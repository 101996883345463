import React, { useCallback, useState, useEffect, useMemo } from 'react'
import cn from 'classnames'
import { connect, ConnectedProps } from 'react-redux'
import { Link, useLocation, useParams } from 'react-router-dom'
import moment from 'moment'
import { t, TRANSLATION } from '../../localization'
import { modalsActionCreators } from '../../state/modals'
import ErrorFrame from '../../components/ErrorFrame'
import Bell from '../../components/Icon/bell'
import TicketsList from '../../components/TicketsList'
import images from '../../constants/images'
import { IRootState } from '../../state'
import { userSelectors, userActionCreators } from '../../state/user'
import { cartActionCreators, cartSelectors } from '../../state/cart'
import { EStatuses, EUserRoles } from '../../types/types'
import { getBase64 } from '../../tools/utils'
import { configSelectors } from '../../state/config'
import * as API from '../../API'
import JSONForm from '../../components/JSONForm'
import Dropdown from '../../components/Dropdown'
import { withLayout } from '../../HOCs/withLayout'
import withAuth from '../../HOCs/withAuth'
import './styles.scss'

const mapStateToProps = (state: IRootState) => ({
  tokens: userSelectors.tokens(state),
  user: userSelectors.user(state),
  language: configSelectors.language(state),
  paidTickets: cartSelectors.getPaid(state),
  informTickets: cartSelectors.getInform(state),
})

const mapDispatchToProps = {
  setMessageModal: modalsActionCreators.setMessageModal,
  updateUser: userActionCreators.initUser,
  getPaidTickets: cartActionCreators.getPaidTickets,
  getInformTickets: cartActionCreators.getInformTickets,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

interface IProps extends ConnectedProps<typeof connector> {
}

const Profile: React.FC<IProps> = ({
  tokens,
  user,
  paidTickets,
  informTickets,
  setMessageModal,
  updateUser,
  getPaidTickets,
  getInformTickets,
}) => {
  const location = useLocation()
  const routeParams: any = useParams()
  const isTickets = location.pathname.indexOf('/profile/tickets') === 0
  const isInform = routeParams.pageName === 'inform'
  const isPassed = routeParams.pageName === 'passed'
  const isFailed = routeParams.pageName === 'failed'

  useEffect(() => {
    if (!isTickets) return
    if (routeParams.pageName !== 'inform') {
      getPaidTickets()
    } else {
      getInformTickets()
    }
  }, [isTickets, routeParams.pageName])

  const ticketsList = useMemo(() => {
    if (!isFailed) {
      return paidTickets
        .filter(item => {
          const { match } = item
          if (!match) return false
          if (isPassed) return moment(match.datetime).isBefore(Date.now())
          return moment(match.datetime).isAfter(Date.now())
        })
        .reduce((acc, item) => {
          const { seats } = item
          const successSeats = Object.keys(seats)
            .filter(seatStr => seats[seatStr].status === 'succeeded')
            .reduce((acc, seatStr) => ({ ...acc, [seatStr]: seats[seatStr] }), {})
          return acc.concat({ ...item, seats: successSeats })
        }, [])
    }

    const tickets: any[] = []
    paidTickets.forEach(item => {
      const { seats } = item
      const failedSeats = Object.keys(seats).filter(seatStr => seats[seatStr].status !== 'succeeded')
      if (failedSeats.length > 0) {
        const newItem = { ...item, seats: {} }
        failedSeats.forEach(seat => {
          newItem.seats[seat] = seats[seat]
        })
        tickets.push(newItem)
      }
    })
    return tickets
  }, [paidTickets, isPassed, isFailed])

  const onChangeAvatar = useCallback(e => {
    const file = e.target.files[0]
    if (!user || !tokens || !file) return
    getBase64(file)
      .then((base64: any) => API.editUser({ u_photo: base64 }))
      .then(() => updateUser())
      .catch(error => alert(JSON.stringify(error)))
  }, [user, tokens])

  const [ isSubmittingForm, setIsSubmittingForm ] = useState(false)
  const [ errors, setErrors ] = useState<Record<string, any>>({})

  const handleChange = useCallback((name: string, value: any) => {
    setErrors({
      ...errors,
      [name]: false,
    })
  }, [errors])

  const handleSubmitForm = useCallback((values: Record<string, any>) => {
    const isChangeRefCode = values.ref_code !== user?.ref_code

    let beforeSave = Promise.resolve(true)
    if (isChangeRefCode) {
      beforeSave = API.checkRefCode(values.ref_code)
        .then(res => {
          if (!res) {
            setErrors({
              ref_code: true,
            })
            return false
          }
          return true
        })
    }

    beforeSave.then((isSuccessBefore) => {
      if (!isSuccessBefore) return
      setIsSubmittingForm(true)
      const { u_photo, ...newValues } = values
      if (user?.u_role === EUserRoles.Client) {
        return API.editUser(newValues)
          .then(() => {
            setMessageModal({
              isOpen: true,
              status: EStatuses.Success,
              message: t(TRANSLATION.SUCCESS_PROFILE_UPDATE_MESSAGE),
            })
          })
          .catch(() =>
            setMessageModal({ isOpen: true, status: EStatuses.Fail, message: 'An error occured' }),
          )
          .finally(() => {
            setIsSubmittingForm(false)
          })
      }
    })
  }, [])

  const formState = useMemo(() => ({
    pending: isSubmittingForm,
  }), [isSubmittingForm])

  const formStr = (window as any).data?.site_constants?.form_profile?.value
  let form
  try {
    form = JSON.parse(formStr)
  } catch (e) {
    return <ErrorFrame title='Bad json in data.js' />
  }

  const userFields = ['u_name', 'u_phone', 'u_email', 'ref_code', 'u_details.subscribe', 'submit']
  form.fields = form.fields.filter((field: any) => userFields.includes(field.name))
  if (user?.u_role === EUserRoles.Driver) {
    form.fields = form.fields.map((item: any) => ({ ...item, disabled: true }))
  }
  return (
    <div
      className="profile"
    >
      <div className="profile__header">
        <div className="profile__avatar">
          <label>
            <div className="profile__avatar-image">
              <div
                className="profile__avatar-image-bg"
                style={{
                  backgroundImage: `url(${user?.u_photo || images.emptyAvatar})`,
                }}
                title={user?.u_name || ''}
              />
            </div>
            <input
              onChange={onChangeAvatar}
              type="file"
              className="profile__avatar-input"
            />
          </label>
        </div>
        <div className="profile__name">
          {user?.u_name || ''}
        </div>
      </div>
      <ul className="profile__nav">
        <li>
          <Link to='/profile' className={cn('profile__link', {
            'profile__link_active': !isTickets
          })}>
            {t('my_profile')}
          </Link>
        </li>
        <li>
          <Link to='/profile/tickets' className={cn('profile__link', {
            'profile__link_active': isTickets
          })}>
            {t('my_tickets')}
          </Link>
        </li>
      </ul>
      {!isTickets && <div className='profile__form'>
        <JSONForm
          defaultValues={user || {}}
          fields={form.fields}
          onSubmit={handleSubmitForm}
          onChange={handleChange}
          state={formState}
          errors={errors}
        />
      </div>}
      {isTickets && <div className='profile__tickets'>
        <div className='profile__tickets-selector'>
          <Dropdown activeKey={routeParams.pageName || 'booked'}>
            <Link className='profile__tickets-link' to='/profile/tickets' key='booked'>{t('booked_tickets')}</Link>
            <Link className='profile__tickets-link' to='/profile/tickets/passed' key='passed'>{t('passed_tickets')}</Link>
            <Link className='profile__tickets-link' to='/profile/tickets/failed' key='failed'>{t('failed_payments')}</Link>
            <Link className='profile__tickets-link' to='/profile/tickets/inform' key='inform'>
              {t('inform_me')} <Bell className='profile__tickets-bell' />
            </Link>
          </Dropdown>
        </div>
      </div>}
      {isTickets && <TicketsList tickets={isInform ? informTickets : ticketsList} isFailed={isFailed} />}
    </div>
  )
}

export default withLayout(withAuth(connector(Profile)))