import React from 'react'
import { Link } from 'react-router-dom'
import './styles.scss'

type TBreadcrumb = {
  title: string,
  link: string
}

const Breadcrumbs: React.FC<{
  items: TBreadcrumb[],
}> = ({
  items
}) => {
  return (
    <ul className='breadcrumbs'>
      {items.map(item => (
        <li className='breadcrumbs__item' key={item.link}>
          <Link to={item.link}>{item.title}</Link>
        </li>
      ))}
    </ul>
  )
}

export default Breadcrumbs