import { all, takeEvery, put } from 'redux-saga/effects'
import { ActionTypes } from './constants'
import { ActionTypes as DataActionTypes } from '../data/constants'
import { ActionTypes as ModalActionTypes } from '../modals/constants'
import { TRANSLATION, t } from '../../localization'
import { EStatuses } from '../../types/types'
import { TAction } from '../../types'
import { call } from '../../tools/sagaUtils'
import * as API from '../../API'

export const saga = function* () {
  yield all([
    takeEvery(ActionTypes.GET_TICKETS, getTicketsSaga),
    takeEvery(ActionTypes.GET_PIAD_TICKETS, getPaidTicketsSaga),
    takeEvery(ActionTypes.GET_INFORM_TICKETS, getInformTickets),
    takeEvery(ActionTypes.BOOK_TICKET, bookTicketSaga),
    takeEvery(ActionTypes.SEND_CART, sendCartSaga),
    takeEvery(ActionTypes.ADD_TO_NOTIFY, addToNotify)
  ])
}

const getInformTickets = function* () {
  try {
    const res: any = yield* call(API.notifyCart)
    const cart = res.data?.data?.cart || []
    const byMatch: Record<string, any> = {}
    cart.forEach((item: any) => {
      if (!byMatch[item.prod]) byMatch[item.prod] = []
      byMatch[item.prod] = byMatch[item.prod].concat(item.prop.split(';'))
    })
    // @ts-ignore
    const tickets = Object.keys(byMatch).reduce((acc, sc_id) => acc.concat({ sc_id, blocks: byMatch[sc_id] }), [])
    yield put({ type: ActionTypes.SET_INFORM_TICKETS, payload: tickets })
  } catch (error) {
    console.error(error)
  }
}

const getTicketsSaga = function* () {
  try {
    yield put({ type: ActionTypes.SET_STATUS, payload: EStatuses.Loading })
    const tickets: any = yield* call(API.getCart)
    const groupedTickets = tickets.reduce((acc: any, item: any) => {
      const matchId = item.sc_id
      if (!acc[matchId]) acc[matchId] = { sc_id: item.sc_id, seats: {} }
      item.price = parseInt(item.price)
      acc[matchId].seats[item.prop] = {
        ...item,
        bookingLimit: item.booking_limit,
        t_id: item.prod,
      }
      return acc
    }, {})
    yield put({ type: ActionTypes.SET_BOOKING_COUNT, payload: tickets.length })
    yield put({ type: ActionTypes.SET_BOOKING, payload: Object.values(groupedTickets) })
    yield put({ type: ActionTypes.SET_STATUS, payload: EStatuses.Success })
  } catch (error) {
    console.error(error)
  }
}

const getPaidTicketsSaga = function* () {
  try {
    const paid: any = yield* call(API.getOrder)
    const byMatch: Record<string, any> = {}
    Object.values(paid).forEach(item => {
      // @ts-ignore
      const tickets = item.b_options.tickets || {}
      const { payment, seats, t_id } = tickets
      if (!seats || !t_id) return
      Object.keys(seats).forEach(tripId => {
        const matchId = t_id[tripId]
        if (!byMatch[matchId]) byMatch[matchId] = {}
        Object.keys(seats[tripId]).forEach(seat => {
          const [ stadiumId, block, row, seatNum ] = seat.split(';')
          byMatch[matchId][seat] = {
            t_id: tripId,
            block,
            row,
            seat: seatNum,
            price: parseFloat(seats[tripId][seat]),
            status: payment
          }
        })
      })
    })
    // @ts-ignore
    const paidArr = Object.keys(byMatch).reduce((acc, matchId) => acc.concat({ sc_id: matchId, seats: byMatch[matchId] }), [])
    yield put({ type: ActionTypes.SET_PAID, payload: paidArr })
  } catch (error) {
    console.error(error)
  }
}

const bookTicketSaga = function* (data: TAction) {
  const { seats, count = 1 } = data.payload

  try {
    yield put({ type: ActionTypes.SET_STATUS, payload: EStatuses.Loading })
    const items = Object.keys(seats).map((seat: any) => ({
      prod: seats[seat].t_id,
      prop: seat,
      count
    }))
    const result: any = yield* call(API.addToCart, items)
    if (result) {
      yield put({ type: ActionTypes.GET_TICKETS })
      yield put({ type: DataActionTypes.GET_MATCH_TICKET })
    }
    yield put({ type: ActionTypes.SET_STATUS, payload: EStatuses.Success })
  } catch (error) {
    console.error(error)
  }
}

const sendCartSaga = function* (data: TAction) {
  const tickets = data.payload
  const result: any = yield* call(API.sendCart, tickets)
  if (result.error) {
    yield put({
      type: ModalActionTypes.SET_MESSAGE_MODAL,
      payload: {
        isOpen: true,
        status: EStatuses.Fail,
        message: t(TRANSLATION.FAILED_PAYMENT_LINK_MESSAGE),
      }
    })
  } else if (typeof result.payment === 'string') {
    window.location.href = result.payment
  }
}

const addToNotify = function* (data: TAction) {
  const { payload } = data
  const result: any = yield* call(API.notifyCart, payload)
}