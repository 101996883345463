import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { IRootState } from '../../state'
import { CURRENCY } from '../../siteConstants'
import { configSelectors } from '../../state/config'
import { t, TRANSLATION } from '../../localization'
import 'moment/locale/ru'
import 'moment/locale/fr'
import './styles.scss'
import images from '../../constants/images'

const mapStateToProps = (state: IRootState) => ({
  language: configSelectors.language(state),
})

const connector = connect(mapStateToProps)

function MatchPreview({
  language,
  datetime,
  top,
  team1,
  team2,
  tournament,
  tickets,
}: any) {
  const langCode = language.iso
  const league = tournament[langCode]
  const team1Name = team1[langCode]
  const team2Name = team2[langCode]
  const stadiumName = '' // stadium ? stadium[langCode] : ''
  const time = moment(datetime).locale(langCode)

  const isSold = typeof tickets === 'object' && !Object.keys(tickets).length
  const ticketsCount = Object.keys(tickets || {}).length
  // @ts-ignore
  const ticketsMinPrice = Math.min(...Object.values(tickets || {}).map(item => item.price))

  return (
    <div className='match-preview'>
      {top && <div className='match-preview__top-label'>Top!</div>}
      <div className='match-preview__details'>
        <div className='match-preview__date'>
          <div className='match-preview__weekday'>{time.format('ddd')}</div>
          <div className='match-preview__day'>{time.format('D MMMM')}</div>
          <div className='match-preview__time'>{time.format('HH.mm')}</div>
        </div>

        <div className='match-preview__info'>
          <div className='match-preview__league'>
            {league}<br />
            {stadiumName}
          </div>
          <div className='match-preview__teams'>
            <span className='match-preview__team'>{team1Name}</span> vs <span className='match-preview__team'>{team2Name}</span>
          </div>
          <div className='match-preview__stock'>
            {ticketsCount === 0 ?
              <span className='match-preview__sold'>{isSold && t(TRANSLATION.TICKETS_SOLD)}</span> :
              <>
                <span className='match-preview__price'>{t(TRANSLATION.PRICE_FROM)} {ticketsMinPrice}{CURRENCY.SIGN}</span>
                <span className='match-preview__instock'>
                  <img src={images.instock} /> {t(TRANSLATION.TICKETS_IN_STOCK)}
                </span>
              </>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default connector(MatchPreview)