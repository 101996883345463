import merge from 'lodash.merge'
import { ActionTypes } from './constants'
import { TAction } from '../../types'

export default function reducer(state = {
  filter: {
    withTicketsOnly: true
  }
}, action: TAction) {
  const { type, payload } = action

  switch (type) {
    case ActionTypes.SET_FORM_FIELD:
      const { form, fields } = payload
      const formState = { [form]: {} }
      Object.keys(fields).forEach((key: string) => {
        let obj: any = formState[form]
        const path = key.split('.')
        path.forEach((name: string, i) => {
          obj[name] = i === path.length - 1 ? fields[key] : {}
          obj = obj[name]
        })
      })
      return merge({}, state, formState)

    default:
      return state
  }
}