import React from 'react'
import { Link } from 'react-router-dom'
import { t } from '../../localization'
import images from '../../constants/images'
import './styles.scss'

interface IProps {

}

const Footer: React.FC<IProps> = () => {
  return (
    <div className="footer">
      <div className="footer__wrapper">
        <Link to='/'>
          <img src={images.logo} className="footer__logo" />
        </Link>
        <div className="footer__nav">
          <Link to="/about-us">{t('page_title_about-us')}</Link>
          <Link to="/faq">{t('page_title_faq')}</Link>
          <Link to="/news">{t('page_title_news')}</Link>
          <Link to="/corporate">{t('page_title_corporate')}</Link>
          {/* <Link to="/">Terms and Conditions</Link> */}
          <Link to="/privacy-policy">{t('page_title_privacy-policy')}</Link>
          <Link to="/become-a-partner">{t('page_title_become-a-partner')}</Link>
          {/* <Link to="/">Affiliate Dashboard</Link> */}
          {/* <Link to="/">Sitemap</Link> */}
        </div>
        <div className="footer__delimiter" />
        <div className="footer__content">
          <div className="footer__copyright">© 2023, tickets4games.com</div>
          <div className="footer__contacts">
            <div className="footer__social">
              <span className="footer__social-icon">
                <img src={images.facebook} />
              </span>
              <span className="footer__social-icon">
                <img src={images.twitter} />
              </span>
            </div>
            +447473747373<br />
            tickets4games@gmail.com
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer