import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { t, TRANSLATION } from '../localization'
import images from '../constants/images'
import history from '../tools/history'
import { modalsActionCreators } from '../state/modals'
import { userSelectors } from '../state/user'
import { IRootState } from '../state'

export default function withAuth<T>(
  Component: React.ComponentType<T>,
) {
  return function AuthComponent(props: any) {
    const dispatch = useDispatch()
    const user = useSelector((state: IRootState) => userSelectors.user(state))
    const isUserLoaded = useSelector((state: IRootState) => userSelectors.initialUserLoaded(state))

    if (!isUserLoaded) return null

    return !user ? 
      <div className="loading-frame-static">
        <div className="loading-frame__title">{t(TRANSLATION.UNAUTHORIZED_ACCESS)}</div>
        <div className="errorIcon" onClick={() => dispatch(modalsActionCreators.setLoginModal(true))}>
          <img src={images.avatar} alt={t(TRANSLATION.ERROR)} style={{ marginTop: '50px' }}/>
        </div>
      </div> :
      <Component {...props} />
  }
}