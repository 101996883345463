import React from 'react'
import cn from 'classnames'
import './styles.scss'

export default function Toggle({
  onChange = (isChecked) => {},
  checked = false
}) {
  return (
    <div
      className={cn('toggle', {
        toggle_checked: checked
      })}
      onClick={() => onChange(!checked)}
    >
      <div className='toggle__slide'></div>
    </div>
  )
}