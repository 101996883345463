import React, { useEffect, useState } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import moment from 'moment'
import 'moment/locale/ru'
import 'moment/locale/fr'
import * as API from '../../API'
import { IRootState } from '../../state'
import { modalsActionCreators, modalsSelectors } from '../../state/modals'
import { configSelectors } from '../../state/config'
import { EStatuses } from '../../types/types'
import Tabs from '../tabs/Tabs'
import './styles.scss'
import Overlay from './Overlay'

const mapStateToProps = (state: IRootState) => ({
  modal: modalsSelectors.getMyTicketsModal(state),
  language: configSelectors.language(state),
})

const mapDispatchToProps = {
  setModal: modalsActionCreators.setMyTicketsModal,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

interface IProps extends ConnectedProps<typeof connector> {
}

const TICKET_TABS = [
  {
    id: 1,
    label: 'Купленные',
  },
  {
    id: 2,
    label: 'Ожидаемые',
  },
]

const MyTicketsModal: React.FC<IProps> = ({
  modal,
  language,
  setModal,
}) => {
  const [ tab, setTab ] = useState(1)
  const langCode = language.iso
  useEffect(() => {
    if (!modal.isOpen) return
    setModal({ isOpen: true, status: EStatuses.Loading })
    API.postDrive().then((res: any) => {
      setModal({
        isOpen: true,
        status: EStatuses.Success,
        tickets: res.data.tickets,
      })
    })
  }, [modal.isOpen])

  let { tickets = [] } = modal

  tickets = tickets.filter(ticket => {
    const date = new Date(ticket.datetime)
    return tab === 1 ? date.getTime() > Date.now() : date.getTime() <= Date.now()
  })
  return (
    <Overlay
      isOpen={modal.isOpen}
      onClick={() => setModal({ isOpen: false })}
    >
      <div
        id="my-tickets-modal"
        className="modal my-tickets-modal"
      >
        <fieldset>
          <legend>Мои билеты</legend>
          {modal.status === EStatuses.Loading ?
            <div className='my-tickets-modal__loader'>
              <img src="/assets/images/GHA/loader.svg" alt='Loading' />
            </div> :
            <div>
              <div className="my-tickets-modal__tabs">
                <Tabs
                  tabs={TICKET_TABS}
                  activeTabID={tab}
                  onChange={id => setTab(id as typeof tab)}
                />
              </div>
              <div className="my-tickets-modal__list">
                {tickets.map(ticket => {
                  return (
                    <div>
                      <div>{moment(ticket.datetime).locale(langCode).format('D MMMM yyyy hh:mm')}</div>
                    </div>
                  )
                })}
              </div>
            </div>
          }
        </fieldset>
      </div>
    </Overlay>
  )
}

export default connector(MyTicketsModal)
