import { TAction } from '../../types/index'
import { ActionTypes } from './constants'

export const getFormSetter = (name: string) => (payload: Record<string, any>): TAction => ({
  type: ActionTypes.SET_FORM_FIELD,
  payload: {
    form: name,
    fields: payload,
  },
})
