import { Record } from 'immutable'
import { ActionTypes } from './constants'
import { TAction } from '../../types'
import { IDataState } from './constants'

export const record = Record<IDataState>({
  tournaments: {},
  teams: {},
  stadiums: {},
  schedule: {},
  ticketsStatus: {},
  tickets: {},
  isLoading: false,
  isLoaded: false,
})

export default function reducer(state = new record(), action: TAction) {
  const { type, payload } = action

  switch (type) {
    case ActionTypes.SET_DATA_LOADING:
      return state
        .set('isLoading', payload)
    case ActionTypes.SET_DATA_LOADED:
      return state
        .set('isLoaded', payload)
    case ActionTypes.SET_DATA:
      return state
        .mergeDeep(payload)
    case ActionTypes.SET_MATCH_TICKETS_STATUS:
      return state
        .mergeIn(['ticketsStatus'], payload)
    case ActionTypes.SET_MATCH_TICKETS:
      return state
        .mergeIn(['tickets'], payload)
    default:
      return state
  }
}