import { useEffect, useRef } from 'react'

const LANG_URI = {
  en: '1gvshtauh',
  ru: '1hb391bti',
  it: '1hb3ahc17',
  de: '1hb71vruu',
  ar: '1hb72eu0h',
  fr: '1hb70o257'
}

const DEFAULT_LANG = 'en'

export default function Tawk({ lang }) {
  useEffect(() => {
    const uri = LANG_URI[lang] || LANG_URI[DEFAULT_LANG]
    const s0 = document.getElementsByTagName('script')[0]
    const s1 = document.createElement('script')
    s1.async = true
    s1.src = `https://embed.tawk.to/645859c46a9aad4bc5796b6f/${uri}`
    s1.charset = 'UTF-8'
    s1.setAttribute('crossorigin', '*')
    s0.parentNode.insertBefore(s1, s0)
  }, [])

  return null
}