import React from 'react'
import { connect, ConnectedProps } from 'react-redux'
import cn from 'classnames'
import Button from '../Button'
import { t, TRANSLATION } from '../../localization'
import { IRootState } from '../../state'
import { modalsActionCreators, modalsSelectors } from '../../state/modals'
import { defaultMessageModal } from '../../state/modals/reducer'
import Overlay from './Overlay'
import images from '../../constants/images'
import { getStatusClassName, getStatusMessage } from '../../tools/utils'
import IconBall from '../Icon/ball'
import './styles.scss'

function isTouchDevice() {
  // @ts-ignore
  return (('ontouchstart' in window) || (navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0))
}

const mapStateToProps = (state: IRootState) => ({
  isOpen: modalsSelectors.isMessageModalOpen(state),
  message: modalsSelectors.messageModalMessage(state),
  status: modalsSelectors.messageModalStatus(state),
})

const mapDispatchToProps = {
  setMessageModal: modalsActionCreators.setMessageModal,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

interface IProps extends ConnectedProps<typeof connector> {
}

const MessageModal: React.FC<IProps> = ({
  isOpen,
  message,
  status,
  setMessageModal,
}) => {
  return (
    <Overlay
      isOpen={isOpen}
      onClick={() => setMessageModal({ ...defaultMessageModal })}
    >
      <div className="modal message-modal">
        <div className={cn('message-modal__header', {
          'message-modal__header_mobile': isTouchDevice(),
          [`message-modal__header_${status && getStatusClassName(status)}`]: status
        })}>
          <img src={images.goal} className='message-modal__goal' />
          <IconBall className='message-modal__ball' />
          <div className='message-modal__header-status'>
            {!!status && getStatusMessage(status)}
          </div>
        </div>

        <div className={cn('message-modal__text', { [`message-modal__text--${status && getStatusClassName(status)}`]: status })}>
          {message}
        </div>
        <Button
          text={t(TRANSLATION.OK)}
          skipHandler
          onClick={() => setMessageModal({ ...defaultMessageModal })}
          style={{
            background: status && getStatusMessage(status) === 'error' ? '#B50000' : '#00650A',
            flex: '0 1',
            margin: '0 auto',
            whiteSpace: 'nowrap',
            height: '28px'
          }}
        />
      </div>
    </Overlay>
  )
}

export default connector(MessageModal)