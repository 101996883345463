import { createSelector } from 'reselect'
import { moduleName } from './constants'
import { getMatch } from '../data/selectors'
import { IRootState } from '../'

export const moduleSelector = (state: IRootState) => state[moduleName]

const getTickets = (type: 'paid' | 'booking' | 'inform') => createSelector(
  moduleSelector,
  state => state,
  (cart, state) => {
    const tickets = cart[type] || []
    return tickets.map(ticket => ({
      ...ticket,
      match: getMatch(ticket.sc_id)(state),
    }))
  },
)

export const getBookingLimit = createSelector(
  moduleSelector,
  cart => {
    const tickets = cart.booking || []
    const times = tickets
      .reduce((res: any, ticket: any) => {
        return res.concat(Object.keys(ticket.seats).map((seat: any) => ticket.seats[seat].bookingLimit))
      }, [])
      .filter((time: any) => time && new Date(time).getTime() > Date.now())
      .map((time: any) => new Date(time).getTime())

    return times.length ? Math.min(...times) : 0
  },
)

export const getBookingPrice = createSelector(
  moduleSelector,
  cart => {
    const tickets = cart.booking || []
    return tickets
      .reduce((res: any, ticket: any) => {
        return res.concat(Object.keys(ticket.seats).map((seat: any) => ticket.seats[seat].price))
      }, [])
      .reduce((sum: number, price: any) => sum + parseInt(price), 0)
  },

)

export const getBooking = getTickets('booking')
export const getPaid = getTickets('paid')
export const getInform = getTickets('inform')
export const getBookingCount = createSelector(
  moduleSelector,
  cart => cart.bookingCount,
)
export const getPaidCount = createSelector(
  moduleSelector,
  cart => cart.paidCount,
)

export const getCartStatus = createSelector(
  moduleSelector,
  cart => cart.status,
)