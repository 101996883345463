import { Record } from 'immutable'
import { ActionTypes } from './constants'
import { TAction } from '../../types'
import { ICartState } from './constants'
import { EStatuses } from '../../types/types'

export const record = Record<ICartState>({
  booking: [],
  bookingCount: 0,
  paid: [],
  paidCount: 0,
  inform: [],
  status: EStatuses.Default,
})

export default function reducer(state = new record(), action: TAction) {
  const { type, payload } = action

  switch (type) {
    case ActionTypes.SET_BOOKING:
      return state
        .set('booking', payload)
    case ActionTypes.SET_BOOKING_COUNT:
      return state
        .set('bookingCount', payload)
    case ActionTypes.SET_PAID:
      return state
        .set('paid', payload)
    case ActionTypes.SET_INFORM_TICKETS:
      return state
        .set('inform', payload)
    case ActionTypes.SET_PAID_COUNT:
      return state
        .set('paidCount', payload)
    case ActionTypes.SET_STATUS:
      return state
        .set('status', payload)
    case ActionTypes.CLEAR_CART:
      return new record()
    default:
      return state
  }
}