import React, { useMemo } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import siteConstants from '../../siteConstants'
import images from '../../constants/images'
import { IRootState } from '../../state'
import { t, TRANSLATION } from '../../localization'
import { formSelectors } from '../../state/form'
import { dataSelectors } from '../../state/data'
import { ordersActionCreators } from '../../state/orders'
import { modalsActionCreators } from '../../state/modals'
import { userSelectors } from '../../state/user'
import { withLayout } from '../../HOCs/withLayout'
import { parseSearchQuery } from '../../tools/utils'
import MatchPreview from '../../components/MatchPreview'
import MatchFilter from '../../components/MatchFilter'
import './styles.scss'

const langCodes = siteConstants.LANGUAGES.map(lang => lang.iso)

const mapStateToProps = (state: IRootState, props: any) => ({
  filter: formSelectors.getForm('filter')(state),
  schedule: dataSelectors.getSchedule(state),
  isLoaded: dataSelectors.getIsDataLoaded(state),
  ticketsMap: dataSelectors.getAllTickets(state),
  user: userSelectors.user(state),
})

const mapDispatchToProps = {
  ...ordersActionCreators,
  setLoginModal: modalsActionCreators.setLoginModal,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

interface IProps extends ConnectedProps<typeof connector> {
  filter: any,
  schedule: any,
  isLoaded: boolean
}

const Match: React.FC<IProps> = ({
  filter = {},
  schedule,
  ticketsMap = {},
  isLoaded,
}) => {
  if (!isLoaded) return null
  const location = useLocation()
  const query = parseSearchQuery(location.search)

  let pageTitle = 'ALL MATCHES'

  const leaguesMap = useMemo(() =>
    schedule.reduce((res: any, item: any) => item.tournament ?
      ({
        ...res,
        [item.tournament.id]: item.tournament.en,
      }) :
      res,
    {})
  , [schedule])

  if (query.league && leaguesMap[query.league]) {
    pageTitle = leaguesMap[query.league]
  }
  if (filter.team) {
    pageTitle = leaguesMap[query.league] ? `${pageTitle}, ${filter.team}` : filter.team
  }

  return (
    <div>
      <div
        className='match-list-page__top'
        style={{
          backgroundImage: `url(${images.headerTop})`,
        }}
      >
      </div>
      <div className='match-list-page__content'>
        <div className='match-list-page__title'>
          {t(TRANSLATION.MATCH_LIST_HEADER)}
        </div>
        <div className='match-list-page__filter'>
          <MatchFilter schedule={schedule} league={query.league} />
        </div>
        <div className='match-list-page__delimiter' />
        <div className='match-list-page__title match-list-page__title_center'>
          {pageTitle}
        </div>
        <div className='match-list-page__schedule'>
          {schedule
            .filter((match: any) => {
              let result = true
              if (!match.top && (query.league && query.league !== '0' && match.tournament.id !== query.league)) result = false
              if (filter.team) {
                // @ts-ignore
                const str = langCodes.reduce((res, code) => `${res};${match.team1 && match.team1[code]};${match.team2 && match.team2[code]}`, '')
                result = result && str.toLowerCase().includes(filter.team.toLowerCase())
              }
              if (filter.withTicketsOnly) {
                result = result && Object.values(ticketsMap[match.id] || {}).length > 0
              }
              return result
            })
            .map((match: any) =>
              <Link to={`/match/${match.id}`} key={match.id}>
                <MatchPreview
                  {...match}
                  tickets={ticketsMap[match.id]}
                />
              </Link>,
            )
          }
        </div>
      </div>
    </div>
  )
}

export default withLayout(connector(Match))