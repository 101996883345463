import React from 'react'
import MetaTags from 'react-meta-tags'
import fieldHref from './field'
import './styles.scss'

export default function Stadium({
  activeSections = [],
  scheme = {},
  blockTickets = [],
  highlightSection = '',
  onSectionClick = (section) => {},
}) {
  const { viewBox, field = {}, sections = {}, text = [] } = scheme

  return (
    <>
      <MetaTags>
        <style>
          {Object.keys(sections).map(section => `
            [data-section="${section}"]>path { fill: ${sections[section].fill[0]} }
            [data-section="${section}"]:hover>path { fill: ${sections[section].fill[1]} }
          `).join('')}
        </style>
      </MetaTags>
      <svg viewBox={viewBox} className='stadium-svg'>
        {!!field.width && !!field.height &&
          <image href={fieldHref} width={field.width} height={field.height} transform={`translate(${field.translate})`} />
        }
        {Object.keys(sections).map(section => {
          const blocks = sections[section].blocks
          const fill = sections[section].fill
          const isDisabled = activeSections.length > 0 && !activeSections.includes(section)
          return (
            <g
              key={section}
              data-section={section}
              className={isDisabled ? 'disabled-section' : ''}
              onClick={() => onSectionClick(section)}
            >
              {Object.keys(blocks).map(block => {
                const rest = {}
                if (blockTickets.includes(block)) {
                  rest.style = { fill: fill[2] }
                  rest.className = 'block-tickets'
                }
                if (highlightSection === section) {
                  rest.style = { fill: fill[1] }
                }
                const d = Array.isArray(blocks[block].d) ? blocks[block].d.join(' ') : blocks[block].d
                return (
                  <path
                    key={block}
                    data-block={block}
                    d={d}
                    {...rest}
                  />
                )
              })}
            </g>
          )
        })}
        {text.map((item, i) => {
          let transform
          if (item.transform) {
            transform = item.transform.indexOf('matrix') === 0 ? item.transform : `translate(${item.transform})`
          }
          return (
            <text
              key={`${item.text}-${i}`}
              fontSize={item.fontSize || 12}
              transform={transform}
              pointerEvents="none"
            >
              {item.text}
            </text>
          )
        })}
      </svg>
    </>
  )
}