import React from 'react'
import { connect, ConnectedProps } from 'react-redux'
import moment from 'moment'
import Button from '../Button'
import { IRootState } from '../../state'
import { configSelectors } from '../../state/config'
import { modalsActionCreators, modalsSelectors } from '../../state/modals'
import Overlay from './Overlay'
import './styles.scss'

const mapStateToProps = (state: IRootState) => ({
  language: configSelectors.language(state),
  modal: modalsSelectors.getOrderTicketsModal(state),
})

const mapDispatchToProps = {
  setOrderTicketsModal: modalsActionCreators.setOrderTicketsModal,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

interface IProps extends ConnectedProps<typeof connector> {
}

const OrderTicketsModal: React.FC<IProps> = ({
  language,
  modal,
  setOrderTicketsModal,
}) => {
  const { tournament = {}, team1 = {}, team2 = {}, stadium = {}, datetime } = modal.match || {}
  const { orderTickets = [] } = modal
  const langCode = language.iso
  const league = tournament[langCode]
  const team1Name = team1[langCode]
  const team2Name = team2[langCode]
  const stadiumName = stadium[langCode]
  const stadiumAddr = stadium[`${langCode}_address`]
  const time = moment(datetime).locale(langCode)
  return (
    <Overlay
      isOpen={modal.isOpen}
      onClick={() => setOrderTicketsModal({ isOpen: false })}
    >
      <div className="modal order-tickets-modal">
        <fieldset>
          <legend>Заказ билетов</legend>
          <div className='tickets-teams'>
            <div className='tickets-team'>{team1Name}</div>
            <div className='tickets-team-del'>—</div>
            <div className='tickets-team'>{team2Name}</div>
          </div>
          <ul className='tickets-summary'>
            <li>
              Турнир: <span>{league}</span>
            </li>
            <li>
              Дата: <span>{time.format('D MMMM')}</span>
            </li>
            <li>
              Время: <span>{time.format('hh:mm')}</span>
            </li>
            <li>
              Стадион: <span>{stadiumName}</span>
            </li>
            <li>
              Адрес: <span>{stadiumAddr}</span>
            </li>
          </ul>
          <div className='tickets-header'>Выбранные билеты</div>
          <ol className='tickets-list'>
            {orderTickets.map((ticket: any) => (
              <li key={ticket}>
                <span className='ticket-value'>{ticket}</span>
                <span className='ticket-price'>120$</span>
              </li>
            ))}
          </ol>
          <div className='tickets-total'>
            К оплате <span className='ticket-price'>{orderTickets.length * 120}$</span>
          </div>
          <Button
            text='Перейти к оплате'
          />
        </fieldset>
      </div>
    </Overlay>
  )
}

export default connector(OrderTicketsModal)