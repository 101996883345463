import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import cn from 'classnames'
import { useHistory } from 'react-router-dom'
import { IRootState } from '../../state'
import { t, TRANSLATION } from '../../localization'
import { configSelectors } from '../../state/config'
import { formActionCreators, formSelectors } from '../../state/form'
import images from '../../constants/images'
import 'moment/locale/ru'
import 'moment/locale/fr'
import './styles.scss'

function capitalizeFirstLetter(str: string) {
  return str[0].toUpperCase() + str.substr(1)
}

const mapStateToProps = (state: IRootState) => ({
  language: configSelectors.language(state),
  filter: formSelectors.getForm('filter')(state),
})

const mapDispatchToProps = {
  setValue: formActionCreators.getFormSetter('filter'),
}

const connector = connect(mapStateToProps, mapDispatchToProps)

function MatchFilter({
  language,
  schedule = [],
  filter = {},
  league,
  setValue,
}: any) {
  const lang = language.iso
  const [ leagues, setLeagues ] = useState([])
  const history = useHistory()
  useEffect(() => {
    if (!schedule.length) return
    const newLeagues = schedule.reduce((res: any, item: any) => item.tournament ?
      ({
        ...res,
        [item.tournament.id]: item.tournament,
      }) :
      res,
    {})
    setLeagues(Object.values(newLeagues))
  }, [schedule])

  return (
    <div className='match-filter'>
      <div className='match-filter__item'>
        <span
          className={cn('match-filter__stock', {
            ['match-filter__stock_off']: !filter.withTicketsOnly,
          })}
          onClick={() => setValue({ withTicketsOnly: !filter.withTicketsOnly })}
        >
          <img src={filter.withTicketsOnly ? images.instock : images.instockEmpty} alt='In stock' />
          {capitalizeFirstLetter(t(TRANSLATION.TICKETS_IN_STOCK))}
        </span>
      </div>
      <div className='match-filter__item'>
        <select
          value={league}
          className='match-filter__input'
          onChange={e => history.push(e.target.value !== '0' ? `?league=${e.target.value}` : '/')}
        >
          <option value='0'>{t(TRANSLATION.FILTER_TOURNAMENTS_PLACEHOLDER)}</option>
          {leagues.map((league: any) => (
            <option key={league.id} value={league.id}>
              {league[lang]}
            </option>
          ))}
        </select>
      </div>
      <div className='match-filter__item'>
        <input
          type='text'
          value={filter.team}
          onChange={e => setValue({ team: e.target.value })}
          className='match-filter__input'
          placeholder={t(TRANSLATION.FILTER_TEAM_PLACEHOLDER)}
        />
      </div>
      <div className='match-filter__item'>
        <span
          className='match-filter__reset'
          onClick={() => {
            setValue({ team: '', withTicketsOnly: false })
            history.push('/')
          }}
        >
          <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.38095 2.09525C2.54172 3.14439 1.33334 5.15963 1.33334 7.42858C1.33334 8.63411 1.69082 9.81256 2.36057 10.8149C3.03032 11.8173 3.98227 12.5985 5.09603 13.0598C6.20979 13.5212 7.43534 13.6419 8.6177 13.4067C9.80006 13.1715 10.8861 12.591 11.7386 11.7386C12.591 10.8861 13.1715 9.80006 13.4067 8.6177C13.6419 7.43534 13.5212 6.20979 13.0598 5.09603C12.5985 3.98228 11.8173 3.03033 10.8149 2.36058C9.81255 1.69082 8.6341 1.33334 7.42857 1.33334" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M4.38095 5.14286V2.09525H1.33334" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
          {t(TRANSLATION.FILTER_RESET)}
        </span>
      </div>
    </div>
  )
}

export default connector(MatchFilter)