import React from 'react'
import { Link } from 'react-router-dom'
import images from '../../constants/images'
import { withLayout } from '../../HOCs/withLayout'
import './styles.scss'

const PageNotFound = function() {
  return (
    <div className='not-found'>
      <div className='not-found__404'>
        4
        <span className='not-found__zero'><img src={images.notFound} /></span>
        4
      </div>
      <div className='not-found__hint'>
        The page does not exist
      </div>
      <div className='not-found__link'>
        <Link to='/'>← Go home</Link>
      </div>
    </div>
  )
}

export default withLayout(PageNotFound)