import React, { useState, useEffect, useRef } from 'react'
import cn from 'classnames'
import Arrow from '../Icon/arrow'
import './styles.scss'

export default function Dropdown(props) {
  const ref = useRef(null)
  const [ isOpened, setIsOpened ] = useState(false)
  const { activeKey, children } = props
  const childArray = React.Children.toArray(children)
  const active = childArray.find(child => child.key === `.$${activeKey}`) || childArray[0]
  const dropdownItems = childArray.filter(child => child !== active)

  useEffect(() => {
    function onClickOutside(e) {
      if (ref.current && !ref.current.contains(e.target)) {
        setIsOpened(false)
      }
    }

    document.addEventListener('click', onClickOutside)
    return () => document.removeEventListener('click', onClickOutside)
  }, [ref])

  useEffect(() => {
    setIsOpened(false)
  }, [activeKey])

  return (
    <div
      className={cn('dropdown', {
        dropdown_opened: isOpened
      })}
      ref={ref}
    >
      <div
        className='dropdown__active'
        onClick={() => setIsOpened(!isOpened)}
      >
        {React.cloneElement(active, { onClick: e => e.preventDefault() })}
        <Arrow className='dropdown__arrow' />
      </div>
      <div className='dropdown__list'>
        {dropdownItems.map(child => (
          <div className='dropdown__list-item'>
            {child}
          </div>
        ))}
      </div>
    </div>
  )
}