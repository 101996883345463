import { userSelectors } from '../state/user'
import { ParametersExceptFirst } from '../types'
import state from '../state'

export interface IApiMethodArguments {
  token: string,
  uHash: string,
  formData: FormData
}

interface apiMethodOptions {
  authRequired?: boolean
}


var chars_digits: Record<string, number> = {
  '0': 0, '1': 1, '2': 2, '3': 3, '4': 4, '5': 5, '6': 6, '7': 7, '8': 8, '9': 9,
  'A': 10, 'B': 11, 'C': 12, 'D': 13, 'E': 14, 'F': 15, 'G': 16, 'H': 17, 'I': 18,
  'J': 19, 'K': 20, 'L': 21, 'M': 22, 'N': 23, 'O': 24, 'P': 25, 'Q': 26, 'R': 27,
  'S': 28, 'T': 29, 'U': 30, 'V': 31, 'W': 32, 'X': 33, 'Y': 34, 'Z': 35,
  'a': 36, 'b': 37, 'c': 38, 'd': 39, 'e': 40, 'f': 41, 'g': 42, 'h': 43, 'i': 44,
  'j': 45, 'k': 46, 'l': 47, 'm': 48, 'n': 49, 'o': 50, 'p': 51, 'q': 52, 'r': 53,
  's': 54, 't': 55, 'u': 56, 'v': 57, 'w': 58, 'x': 59, 'y': 60,
  '\'': 61, '-': 62, '.': 63, '_': 64,
}

const digits_chars: Record<number, string> = {}
for (let _char in chars_digits) {
  digits_chars[chars_digits[_char]] = _char
}

let index_digit: Record<string, any> = {
  '0': {
    '1':'stadium',
    '2':'schedule',
    '4':'team',
    '8':'tournament',
  },
}

function sum_keys(obj: Record<string, any>) {
  var sum = 0
  for(var key in obj){
    sum += Number(key)
  }
  return sum
}

export const getFieldsPar = (str_arr: string[]) => {
  let fields: Record<string, any> = {}
  for (let index in index_digit) {
    let digits = index_digit[index]
    for (let digit in digits) {
      fields[digits[digit]] = [index, digit]
    }
  }
  let value: Record<string, any> = {}
  let max_index = -1
  for (let key in str_arr) {
    if (fields[str_arr[key]]){
      if (!(fields[str_arr[key]][0] in value)) {
        value[fields[str_arr[key]][0]] = {}
        if (fields[str_arr[key]][0] > max_index) max_index = fields[str_arr[key]][0]
      }
      value[fields[str_arr[key]][0]][fields[str_arr[key]][1]] = true
    }
  }

  let value_str: string = ''
  for (let i = 0; i <= max_index; i++) {
    if (i in value) {
      let digit = sum_keys(value[i])
      value_str += digit in digits_chars ? digits_chars[digit] : '0'
    } else {
      value_str += '0'
    }
  }
  return value_str
}


export const apiMethod = <T extends (...args: any[]) => any>(
  method: (...args: Parameters<T>) => ReturnType<T>,
  {
    authRequired = true,
  }: apiMethodOptions = {},
) => {
  return (...args: ParametersExceptFirst<T>): ReturnType<T> => {
    const formData = new FormData()
    let tokens

    if (authRequired) {
      tokens = userSelectors.tokens(state.getState())
      if (!tokens) {
        console.error('Auth failed for API call')
        return Promise.reject(new Error('Unauthorized user')) as ReturnType<T>
      }

      formData.append('token', tokens.token)
      formData.append('u_hash', tokens.u_hash)
    }

    const parameters = [
      {
        token: tokens?.token,
        u_hash: tokens?.u_hash,
        formData,
      }, ...args,
    ] as Parameters<T>
    return method(...parameters)
  }
}

export interface IResponseFields {
  /** Список валидных ключей */
  affected_fields: string[],
  /** Список невалидных ключей */
  forbidden_fields: string[],
  /** Список ключей с некорректными данными */
  wrong_data_fields?: string[]
}

export const addToFormData = (formData: FormData, object: {[key: string]: any}) => {
  for (let [key, value] of Object.entries(object)) {
    if (!value) continue
    formData.append(key, value)
  }
  return formData
}