import { createSelector } from 'reselect'
import { moduleName } from './constants'
import siteConstants from '../../siteConstants'
import { IRootState } from '../'

export const moduleSelector = (state: IRootState) => state[moduleName]
export const getIsDataLoading = createSelector(moduleSelector, state => state.isLoading)
export const getIsDataLoaded = createSelector(moduleSelector, state => state.isLoaded)
export const getData = (name: string) => createSelector(moduleSelector, state => state[name])

const strToDate = (str: string) => {
  const [ dateStr, timeStr ] = str.substr(0, str.length - 6).split(' ')
  const [ year, month, date ] = dateStr.split('-').map(Number)
  const [ hour, min, sec ] = timeStr.split(':').map(Number)
  return new Date(year, month - 1, date, hour, min, sec)
}

export const getMatchTicketsStatus = (match: string) => createSelector(
  moduleSelector,
  state => state.ticketsStatus[match],
)

export const getAllTickets = createSelector(
  moduleSelector,
  state => state.tickets,
)

export const getMatchTickets = (match: string) => createSelector(
  moduleSelector,
  state => state.tickets[match],
)

const extendStadium = (id: string, stadium: Record<string, any>) => !stadium ?
  null :
  ({
    ...stadium,
    id,
    city: siteConstants.getCity(stadium.city),
    country: siteConstants.getCountry(stadium.country),
  })

export const getStadium = (id: string) => createSelector(
  getData('stadiums'),
  (stadiums = {}) => extendStadium(id, stadiums[id]),
)

const extendTeam = (id: string, team: Record<string, any>, stadiums: Record<string, any>) => !team ?
  null :
  ({
    ...team,
    id,
    city: siteConstants.getCity(team.city),
    country: siteConstants.getCountry(team.country),
    stadium: extendStadium(team.stadium, stadiums[team.stadium]),
  })

export const getTeam = (id: string) => createSelector(
  getData('teams'),
  getData('stadiums'),
  (teams = {}, stadiums = {}) => extendTeam(id, teams[id], stadiums),
)

export const getTournament = (id: string) => createSelector(
  getData('tournaments'),
  (tournaments = {}) => tournaments[id] || null,
)

export const getMatch = (id: string) => createSelector(
  getData('schedule'),
  getData('tournaments'),
  getData('teams'),
  getData('stadiums'),
  (schedule = {}, tournaments = {}, teams = {}, stadiums = {}) => {
    const match = schedule[id]
    if (!match) return null
    return {
      id,
      datetime: strToDate(match.datetime),
      seats: match.seats,
      team1: extendTeam(match.team1, teams[match.team1], stadiums),
      team2: extendTeam(match.team2, teams[match.team2], stadiums),
      stadium: extendStadium(match.stadium, stadiums[match.stadium]),
      tournament: {
        id: match.tournament,
        ...tournaments[match.tournament],
      },
    }
  },
)

export const getSchedule = createSelector(
  getData('schedule'),
  getData('tournaments'),
  getData('teams'),
  getData('stadiums'),
  (schedule = {}, tournaments = {}, teams = {}, stadiums = {}) => {
    const keys = Object.keys(schedule)
    const matches = Object.values(schedule)
    return matches.map((match: any, i: number) => ({
      id: keys[i],
      datetime: strToDate(match.datetime),
      seats: match.seats,
      top: !!Number(match.top),
      team1: extendTeam(match.team1, teams[match.team1], stadiums),
      team2: extendTeam(match.team2, teams[match.team2], stadiums),
      stadium: extendStadium(match.stadium, stadiums[match.stadium]),
      tournament: {
        id: match.tournament,
        ...tournaments[match.tournament],
      }
    }))
      .sort((match1, match2) => Number(match1.datetime) - Number(match2.datetime))
      .sort((match1, match2) => match1.top && !match2.top ? -1 : 1)
  },
)
