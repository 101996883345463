import React, { useState, useEffect } from 'react'
import cn from 'classnames'
import moment from 'moment'
import * as API from '../../API'
import images from '../../constants/images'
import { CURRENCY } from '../../siteConstants'
import './styles.scss'

export default function TicketsList({
  tickets = [],
  isFailed
}) {
  const [ activeTicket, setActiveTicket ] = useState()

  useEffect(() => {
    if (!activeTicket) return
    API.getTicketFile(activeTicket)
  }, [activeTicket])

  return (
    <div
      className='tickets-list'
    >
      {tickets.map(item => {
        const { match, seats, blocks } = item
        if (!match) return null
        // @TODO Set locale from language.iso
        const datetime = moment(match.datetime).locale('en')
        return (
          <div className='tickets-list__item' key={match.id}>
            <div className={cn('tickets-list__header', {
              'tickets-list__header_failed': isFailed
            })}>
              <div className='tickets-list__datetime'>
                {datetime.format('DD MMMM')}<br />
                {datetime.format('HH:mm')}
              </div>
              <div className='tickets-list__weekday'>
                {datetime.format('ddd')}
              </div>
            </div>
            <div className='tickets-list__match'>
              <b>{match.team1.en}</b> vs. <b>{match.team2.en}</b><br />
              {match.tournament.en}<br />
              {match.stadium ? match.stadium.en : match.team1.stadium.en}
              <div className='tickets-list__list'>
                {!!seats && <div className='tickets-list__links'>
                  {Object.keys(seats).map(seatStr => {
                    const seat = seats[seatStr]
                    return(
                      <div
                        className={cn('tickets-list__ticket', {
                          'tickets-list__ticket_failed': isFailed
                        })}
                        onClick={() => {
                          if (isFailed) return
                          setActiveTicket({ t_id: seat.t_id, seat: seatStr })
                        }}
                      >
                        {!isFailed && <img src={images.qr} className='tickets-list__qr' />}
                        <div>
                          Block {seat.block}<br />
                          Row {seat.row}<br />
                          Seat {seat.seat}<br />
                          <b>{seat.price}{CURRENCY.SIGN}</b>
                        </div>
                      </div>
                    )
                  })}
                </div>}
                {!!blocks && <div>
                  Notify when tickets appear in blocks<br />
                  {blocks.join(', ')}  
                </div>}
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )
}