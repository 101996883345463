import React, { useEffect, useState, useMemo } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import * as API from '../../API'
import { IRootState } from '../../state'
import { dataActionCreators, dataSelectors } from '../../state/data'
import { ordersActionCreators } from '../../state/orders'
import { modalsActionCreators } from '../../state/modals'
import { configSelectors } from '../../state/config'
import { userSelectors } from '../../state/user'
import { withLayout } from '../../HOCs/withLayout'
import Match from '../../components/Match'
import Breadcrumbs from '../../components/Breadcrumbs'
import './styles.scss'

export const getTicketsTogetherScheme = (tickets: Record<string, any>, sectionByBlock: Record<string, any>) => {
  if (!tickets) return { blocks: {}, sections: {} }

  const byBlock = Object.keys(tickets).reduce((acc: Record<string, any>, seatStr) => {
    const [ block, row, seat ] = seatStr.split(';')
    // Уникальный ключ, включающий в себя ряд, для каждого места
    // Ряд умнодаем на 1000 на случай, если в ряду окажется больше 100 мест
    const key = Number(row) * 1000 + Number(seat)
    const ticket = tickets[seatStr]
    if (!acc[ticket.block]) acc[ticket.block] = []
    acc[ticket.block].push(key)
    return acc
  }, {})

  Object.values(byBlock)
    .map(item => {
      item = item.sort((a: string, b: string) => parseInt(a) > parseInt(b) ? 1 : -1)
    })
  
  const blocks = Object.keys(byBlock)
    .reduce((acc, block) => {
      const item = byBlock[block]
      let count = 1
      let lastSeat = -1
      let prevSeat = -1
      // @ts-ignore
      item.forEach(seatStr => {
        const seat = parseInt(seatStr)
        if (seat === prevSeat + 1) {
          count++
          prevSeat = seat
        }
        else {
          if (lastSeat > 0) {
            // @ts-ignore
            if (!acc[block]) acc[block] = []
            // @ts-ignore
            acc[block].push({ start: lastSeat, count })
          }
          count = 1
          prevSeat = seat
          lastSeat = seat
        }
      })
      if (lastSeat > 0) {
        // @ts-ignore
        if (!acc[block]) acc[block] = []
        // @ts-ignore
        acc[block].push({ start: lastSeat, count })
      }
      return acc
    }, {})

  const sections = Object.keys(blocks)
    .reduce((acc, block) => {
      // @ts-ignore
      const item = blocks[block]
      const sectionName = sectionByBlock[block]
      const max = Math.max(...item.map((item: any) => item.count))
      // @ts-ignore
      if (!acc[sectionName] || acc[sectionName] < max) acc[sectionName] = max
      return acc
    }, {})

  return {
    blocks,
    sections
  }
}

const mapStateToProps = (state: IRootState, props: any) => ({
  language: configSelectors.language(state),
  game: dataSelectors.getMatch(props.match?.params?.id)(state),
  ticketsStatus: dataSelectors.getMatchTicketsStatus(props.match?.params?.id)(state),
  tickets: dataSelectors.getMatchTickets(props.match?.params?.id)(state),
  isLoaded: dataSelectors.getIsDataLoaded(state),
  user: userSelectors.user(state),
})

const mapDispatchToProps = {
  ...ordersActionCreators,
  setLoginModal: modalsActionCreators.setLoginModal,
  getTickets: dataActionCreators.getMatchTicket,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

interface IProps extends ConnectedProps<typeof connector> {
  match: any,
  isLoaded: boolean,
  game: any
}

const MatchPage: React.FC<IProps> = ({
  language,
  match,
  game,
  isLoaded,
  ticketsStatus,
  tickets,
  getTickets,
}) => {
  const [ sectionByBlock, setSectionByBlock ] = useState({})
  const [ stadiumLoaded, setStadiumLoaded ] = useState(false)
  const [ stadiumScheme, setStadiumScheme ] = useState({})

  const ticketsTogether = useMemo(
    () => getTicketsTogetherScheme(tickets, sectionByBlock),
    [tickets, sectionByBlock]
  )

  useEffect(() => {
    if (!isLoaded) return
    getTickets(match?.params?.id)
    const stadiumId = game?.stadium?.id || game?.team1?.stadium?.id
    if (stadiumId) {
      API.getData(['stadium'], { key: stadiumId }).then(res => {
        setStadiumLoaded(true)
        const data = res.stadiums[stadiumId]
        let scheme: Record<string, any> = {}
        try {
          scheme = JSON.parse(data.scheme.replaceAll("'", "\""))
        } catch(e) {
          scheme = data.scheme_blob
          console.error(e)
        }
        if (scheme.sections) {
          const blocksMap: Record<string, any> = {}
          Object.keys(scheme.sections).forEach(section => {
            const blocks = scheme.sections[section]?.blocks
            Object.keys(blocks).forEach(block => {
              blocksMap[block] = section
            })
          })
          setSectionByBlock(blocksMap)
        }
        setStadiumScheme(scheme)
      })
    }
  }, [isLoaded])
  if (!isLoaded || !game) return null
  const leagueTitle = game.tournament[language.iso]
  const matchTitle = `${game.team1[language.iso]} vs ${game.team2[language.iso]}`
  return (
    <div className='match-page'>
      <Breadcrumbs
        items={[
          {
            title: 'Home',
            link: '/'
          },
          {
            title: leagueTitle,
            link: `/?league=${game.tournament.id}`
          },
          {
            title: matchTitle,
            link: `/match/${game.id}`
          }
        ]}
      />
      <Match
        {...game}
        seats={tickets}
        seatsTogether={ticketsTogether}
        ticketsStatus={ticketsStatus}
        sectionByBlock={sectionByBlock}
        stadiumLoaded={stadiumLoaded}
        stadiumScheme={stadiumScheme}
      />
    </div>
  )
}

export default withLayout(connector(MatchPage))