import React, { useState, useRef, useEffect } from 'react'
import cn from 'classnames'
import { connect, ConnectedProps } from 'react-redux'
import { useLocation, Link } from 'react-router-dom'
import { HamburgerButton } from 'react-hamburger-button'
import history from '../../tools/history'
import { t, TRANSLATION } from '../../localization'
import { useInterval } from '../../tools/hooks'
import moment from 'moment'
import images from '../../constants/images'
import Arrow from '../Icon/arrow'
import { IRootState } from '../../state'
import { EBookingDriverState } from '../../types/types'
import { configSelectors, configActionCreators } from '../../state/config'
import { modalsActionCreators } from '../../state/modals'
import { clientOrderSelectors } from '../../state/clientOrder'
import { ordersSelectors } from '../../state/orders'
import { userSelectors, userActionCreators } from '../../state/user'
import { cartSelectors } from '../../state/cart'
import SITE_CONSTANTS from '../../siteConstants'
import './styles.scss'

interface IMenuItem {
  label: string
  action?: (index: number) => any
  href?: string
}

const mapStateToProps = (state: IRootState) => ({
  user: userSelectors.user(state),
  language: configSelectors.language(state),
  activeOrders: ordersSelectors.activeOrders(state),
  selectedOrder: clientOrderSelectors.selectedOrder(state),
  bookingCount: cartSelectors.getBookingCount(state),
})

const mapDispatchToProps = {
  setLanguage: configActionCreators.setLanguage,
  setLoginModal: modalsActionCreators.setLoginModal,
  setProfileModal: modalsActionCreators.setProfileModal,
  setMyTicketsModal: modalsActionCreators.setMyTicketsModal,
  logout: userActionCreators.logout,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

interface IProps extends ConnectedProps<typeof connector> {

}

const Header: React.FC<IProps> = ({
  user,
  language,
  activeOrders,
  selectedOrder,
  bookingCount,
  setLanguage,
  setLoginModal,
  logout,
}) => {
  const navRef = useRef(null)
  const langRef = useRef(null)
  const [languagesOpened, setLanguagesOpened] = useState(false)
  const [seconds, setSeconds] = useState(0)
  const [menuOpened, setMenuOpened] = useState(false)

  const location = useLocation()

  const clientOrder = activeOrders?.find(item => item.b_id === selectedOrder)
  const driver = clientOrder?.drivers?.find(item =>
    item.c_state > EBookingDriverState.Canceled || item.c_state === EBookingDriverState.Considering,
  )

  const menuItems: IMenuItem[] = []

  if (user) {
    menuItems.push(
      {
        label: 'Home',
        href: '/',
      },
      {
        label: t('profile'),
        href: '/profile',
      },
      {
        label: 'My tickets',
        href: '/profile/tickets',
      },
      {
        label: 'Logout',
        action: logout,
      }
    )
  }

  useInterval(() => {
    if (clientOrder) {
      if (driver) return setSeconds(0)
      const _seconds = moment().diff(clientOrder?.b_start_datetime, 'seconds')
      if (_seconds > (clientOrder?.b_max_waiting || SITE_CONSTANTS.WAITING_INTERVAL)) return setSeconds(0)
      setSeconds(_seconds)
    } else if (seconds !== 0) setSeconds(0)
  }, 1000)

  const toggleLanguagesOpened = () => setLanguagesOpened(prev => !prev)
  const toggleMenuOpened = () => setMenuOpened(prev => !prev)

  useEffect(() => {
    function onNavClickOutside(e: any) {
      // @ts-ignore
      if (navRef.current && !navRef.current.contains(e.target)) {
        setMenuOpened(false)
      }
    }

    document.addEventListener('click', onNavClickOutside)
    return () => document.removeEventListener('click', onNavClickOutside)
  }, [navRef])

  useEffect(() => {
    function onLangClickOutside(e: any) {
      // @ts-ignore
      if (langRef.current && !langRef.current.contains(e.target)) {
        setLanguagesOpened(false)
      }
    }

    document.addEventListener('click', onLangClickOutside)
    return () => document.removeEventListener('click', onLangClickOutside)
  }, [langRef])

  const isProfile = location.pathname.indexOf('/profile') === 0

  return (
    <header
      className={cn('header', {
        header_profile: isProfile
      })}
    >
      <div className="header__wrapper">
        {isProfile && <div className='header__title'>
          Profile
        </div>}
        <div className="column" style={{ marginTop: '5px' }}>
          <div
            className="menu__wrapper"
            ref={navRef}
          >
            <HamburgerButton
              open={menuOpened}
              onClick={() => toggleMenuOpened()}
              width={25}
              height={12}
              strokeWidth={2}
              color='white'
              animationDuration={0.5}
            />
            <ul className={cn('menu__list', { 'menu__list--active': menuOpened })}>
              {menuItems.map((item, index) => (
                <li key={index} className="menu__item">
                  <button
                    onClick={() =>
                      item.href ?
                        history.push(item.href) :
                        item.action && (() => {setMenuOpened(false); item.action(index)})()
                    }
                    className="menu__button"
                  >
                    {item.label}
                  </button>
                </li>
              ))}
            </ul>
          </div>

          <div className='header__logo'>
            <Link to='/'>
              <img src={images.logo} alt='TKB24' />
            </Link>
          </div>
        </div>
        {isProfile && <div className='column'>
          <Link to="/" className="header__back">Home</Link>
        </div>}
        <div className="column column_right">
          <div
            onClick={() => toggleLanguagesOpened()}
            className='langs-container'
            ref={langRef}
          >
            {language.iso}
            <Arrow
              style={{
                transform: `rotate(${languagesOpened ? 180 : 0}deg)`
              }}
            />

            <span
              className="languages"
              style={{ display: languagesOpened ? 'flex' : 'none' }}
            >
              {
                SITE_CONSTANTS.LANGUAGES.filter(item => item.iso !== language.iso).map(item => (
                  <span onClick={e => setLanguage(item)}>{item.iso}</span>
                ))
              }
            </span>

          </div>
          {!!user &&
            <div
              className="header__icon header__iconCart"
              onClick={e => history.push('/cart')}
              style={{
                backgroundImage: `url(${images.cart})`,
              }}
            >
              {bookingCount ? <span className="header__iconMarker">{bookingCount}</span> : null}
            </div>
          }
          <a
            className="header__icon"
            style={{
              backgroundImage: `url(${images.contacts})`,
            }}
            href='tel:+447473747373'
          />
          <div
            className="header__icon header__iconUser"
            onClick={e => user ? history.push('/profile') : setLoginModal(true)}
            style={{
              backgroundImage: `url(${user?.u_photo || images.user})`,
            }}
          >
            {user && <svg className="header__icon_check" xmlns="http://www.w3.org/2000/svg" width="12px" height="12px" viewBox="0 0 24 24" fill="none">
              <path fillRule="evenodd" clipRule="evenodd" d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM16.0303 8.96967C16.3232 9.26256 16.3232 9.73744 16.0303 10.0303L11.0303 15.0303C10.7374 15.3232 10.2626 15.3232 9.96967 15.0303L7.96967 13.0303C7.67678 12.7374 7.67678 12.2626 7.96967 11.9697C8.26256 11.6768 8.73744 11.6768 9.03033 11.9697L10.5 13.4393L12.7348 11.2045L14.9697 8.96967C15.2626 8.67678 15.7374 8.67678 16.0303 8.96967Z" fill="#fff"/>
            </svg>}
          </div>
        </div>
      </div>
    </header>
  )
}

export default connector(Header)