import { TAction } from '../../types/index'
import { ActionTypes } from './constants'
import { EStatuses } from '../../types/types'

export const setDataLoading = (payload: boolean): TAction => ({
  type: ActionTypes.SET_DATA_LOADING,
  payload,
})
export const setDataLoaded = (payload: boolean): TAction => ({
  type: ActionTypes.SET_DATA_LOADED,
  payload,
})
export const setData = (payload: Record<string, any>): TAction => ({
  type: ActionTypes.SET_DATA,
  payload,
})
export const getMatchTicket = (payload?: string): TAction => ({
  type: ActionTypes.GET_MATCH_TICKET,
  payload,
})
export const setMatchTicketsStatus = (payload: EStatuses): TAction => ({
  type: ActionTypes.SET_MATCH_TICKETS_STATUS,
  payload,
})
