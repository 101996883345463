import { appName } from '../../config'

export interface IDataState {
  isLoading: boolean,
  isLoaded: boolean,
  [key: string]: any
}

export const moduleName = 'data'

const prefix = `${appName}/${moduleName}`

export const ActionTypes = {
  SET_DATA_LOADING: `${prefix}/SET_DATA_LOADING`,
  SET_DATA_LOADED: `${prefix}/SET_DATA_LOADED`,
  SET_DATA: `${prefix}/SET_DATA`,
  GET_MATCH_TICKET: `${prefix}/GET_MATCH_TICKET`,
  SET_MATCH_TICKETS_STATUS: `${prefix}/SET_MATCH_TICKETS_STATUS`,
  SET_MATCH_TICKETS: `${prefix}/SET_MATCH_TICKETS`,
} as const
