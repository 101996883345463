import React from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { useLocation } from 'react-router-dom'
import './login-form.scss'
import VersionInfo from '../../version-info'
import { t, TRANSLATION } from '../../../localization'
import LoadFrame from '../../LoadFrame'
import LoginForm from './Login'
import RegisterForm from './Register'
import { LOGIN_TABS_IDS } from '../../../state/user/constants'
import { IRootState } from '../../../state'
import { EStatuses } from '../../../types/types'
import {
  userSelectors,
  userActionCreators,
} from '../../../state/user'
import { modalsActionCreators, modalsSelectors } from '../../../state/modals'
import Overlay from '../Overlay'

const mapStateToProps = (state: IRootState) => ({
  isOpen: modalsSelectors.isLoginModalOpen(state),
  user: userSelectors.user(state),
  status: userSelectors.status(state),
  tab: userSelectors.tab(state),
  message: userSelectors.message(state),
})

const mapDispatchToProps = {
  setLoginModal: modalsActionCreators.setLoginModal,
  login: userActionCreators.login,
  register: userActionCreators.register,
  logout: userActionCreators.logout,
  remindPassword: userActionCreators.remindPassword,
  setTab: userActionCreators.setTab,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

interface IProps extends ConnectedProps<typeof connector> {
}

const LoginModal: React.FC<IProps> = ({
  isOpen,
  user,
  status,
  tab,
  setTab,
  setLoginModal,
}) => {
  const location = useLocation()

  return (
    <Overlay
      isOpen={isOpen}
      onClick={() => setLoginModal(false)}
    >
      <div className="modal login-modal">
        {status === EStatuses.Loading && <LoadFrame/>}
        <div className="login-modal__header">
          {tab === 'sign-in' ? t(TRANSLATION.SIGN_IN_HEADER) : t(TRANSLATION.SIGNUP)}
        </div>

        <div className="login">
          {tab === LOGIN_TABS_IDS[0] ?
            (
              <LoginForm
                isOpen={isOpen}
                goToSignup={() => setTab('sign-up')}
              />
            ) :
            (
              <RegisterForm
                isOpen={isOpen}
                goToSignin={() => setTab('sign-in')}
              />
            )}
        </div>
        <VersionInfo/>
      </div>
    </Overlay>
  )
}

export default connector(LoginModal)
