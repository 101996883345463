import React, { Suspense, lazy } from 'react'
import { Route, Switch } from 'react-router-dom'
import {
  configSelectors,
} from './state/config'
import { connect, ConnectedProps } from 'react-redux'
import images from './constants/images'
import { t, TRANSLATION } from './localization'
import { IRootState } from './state'
import { EStatuses, IUser } from './types/types'
import { userSelectors } from './state/user'
import Sandbox from './pages/Sandbox'
import PageNotFound from './pages/PageNotFound'
import MatchList from './pages/MatchList'
import Match from './pages/Match'
import Cart from './pages/Cart'
import Content from './pages/Content'
import Profile from './pages/Profile'

const mapStateToProps = (state: IRootState) => ({
  status: configSelectors.status(state),
  user: userSelectors.user(state),
})

const connector = connect(mapStateToProps)

interface IProps extends ConnectedProps<typeof connector> {

}

const AppRoutesWrapper: React.FC<IProps> = ({ status, user }) => {
  return status === EStatuses.Success ?
    <Suspense fallback={null}><AppRoutes user={user}/></Suspense> :
    <UnavailableBase/>
}

const UnavailableBase = () => {
  return <section>
    <div className="loading-frame">
      <img src={images.error} alt={t(TRANSLATION.ERROR)}/>
      <div className="loading-frame__title">{t(TRANSLATION.DATABASE_IS_UNAVAILABLE)}</div>
    </div>
  </section>
}

const AppRoutes: React.FC<{user: IUser | null}> = ({ user }) => (
  <Switch>
    <Route exact path="/" component={MatchList} />
    <Route path="/match/:id" component={Match} />
    <Route path="/cart" component={Cart} />
    <Route path={["/profile", "/profile/tickets", "/profile/tickets/:pageName"]} component={Profile} exact />
    <Route
      path={[
        '/about-us',
        '/faq',
        '/corporate',
        '/become-a-partner',
        '/privacy-policy',
        '/news',
      ]}
      component={Content}
      exact
    />

    <Route path="/sandbox" component={Sandbox}/>

    <Route path="*" component={PageNotFound}/>
  </Switch>
)

export default connector(AppRoutesWrapper)
