export default {
  UPLOADING_DESCRIPTION: 'uploading_description',
  UNLOADING_DESCRIPTION: 'unloading_description',
  ADVERTISEMENT_DESCRIPTION: 'advertisement_description',
  FACTORING_DESCRIPTION: 'factoring_description',
  MONITORING_DESCRIPTION: 'monitoring_description',
  ESCORT_DESCRIPTION: 'escort_description',
  SECURE_TRANSACTION_DESCRIPTION: 'secure_transaction_description',
  FORWARDING_DESCRIPTION: 'forwarding_description',
  CUSTOMS_SERVICES_DESCRIPTION: 'customs_services_description',
  NOTHING: 'nothing',
  INSURANCE_DESCRIPTION: 'insurance_description',
  INTERVAL: 'interval',
  EQUAL: 'equal',
  LESS: 'less',
  MORE: 'more',
  INCREMENT: 'increment',
  DECREMENT: 'decrement',
  DAYS_SHORT: 'days_short',
  CANDIDATE: 'candidate',
  PRICE_PERFORMER: 'price_performer',
  MAKE_OFFER: 'make_offer',
  MASSAGE_CHAIR: 'massage_chair',
  COUCH_RECLINER: 'couch_recliner',
  CHILDRENS_ROOM: 'childrens_room',
  ADDITIONAL_SERVICES_P: 'additional_services_p',
  INSURANCE: 'insurance_p',
  UNLOADING: 'unloading_p',
  ADVERTISEMENT: 'advertisement_p',
  FACTORING: 'factoring_p',
  MONITORING: 'monitoring_p',
  ACCOMPANIMENT: 'accompaniment_p',
  SAFE_DEAL: 'safe_deal_p',
  LOADING_PLACE: 'loading_place_p',
  FORWARDING: 'forwarding_p',
  CUSTOMS_SERVICES: 'customs_services_p',
  ONE: 'one',
  ONE_ROOM_ERROR: 'one_room_error',
  TOTAL_ITEMS: 'total_items',
  ATTACHMENTS: 'attachments',
  ACCEPT_AND_CLOSE: 'accept_and_close',
  ACTUAL: 'actual',
  ADDRESSES: 'addresses',
  ADD_CARD: 'add_card',
  ADD_IMAGES: 'add_images',
  ADD_ITEM: 'add_item',
  ADD_TAXES: 'add_taxes',
  ADMINISTRATOR: 'administrator',
  ADULT_BICYCLE: 'adult_bicycle',
  ALARM: 'alarm',
  ALL: 'all',
  APARTAMENT_SHEDULE: 'apartament_shedule',
  APPROXIMATE_TIME: 'approximate_time',
  APRIL: 'april',
  ARMCHAIR: 'armchair',
  ARMCHAIR_RECLINER: 'armchair_recliner',
  ARRIVED: 'arrived',
  ARROW: 'arrow',
  ART_PICTURE: 'art_picture',
  ART_PICTURES: 'art_pictures',
  ATTACH_CARD: 'attach_card',
  ATTIC: 'attic',
  AUGUST: 'august',
  AUTO_CLASS: 'auto_class',
  BANK_CARD: 'bank_card',
  BAR: 'bar',
  BAR_STOOL: 'bar_stool',
  BASEMENT: 'basement',
  BEDROOM: 'bedroom',
  BEDSIDE_TABLE: 'bedside_table',
  BENCH: 'bench',
  BICYCLE: 'bicycle',
  BICYCLES: 'bicycles',
  BIKE: 'bike',
  BOOKCASE: 'bookcase',
  BOOKSHELF: 'bookshelf',
  BOOK_CASE: 'book_case',
  BOOK_SHELF: 'book_shelf',
  BOXING: 'boxing',
  BOXING_REQUIRED: 'boxing_required',
  BUILD_THE_ROUTE: 'build_the_route',
  CANCEL: 'cancel',
  CANCELED: 'canceled',
  CANCEL_ALARM: 'cancel_alarm',
  CANCEL_AND_CLOSE: 'cancel_and_close',
  CANCEL_ORDER: 'cancel_order',
  CANCEL_ORDER_CONFIRMATION: 'cancel_order_confirmation',
  CAR: 'car',
  CARD: 'card',
  CARD_NUMBER: 'card_number',
  CARD_NUMBER_PATTERN_ERROR: 'card_number_pattern_error',
  CARGO_P: 'cargo_p',
  CARGO_DESCRIPTION_PLACEHOLDER: 'cargo_description_placeholder',
  CARGOS_P: 'cargos_p',
  CARGO_TYPE_P: 'cargo_type_p',
  CARGO_VOLUME_P: 'cargo_volume_p',
  CARGO_WEIGHT_P: 'cargo_weight_p',
  CAR_QUANTITY_P: 'car_quantity_p',
  CAR_TYPE_P: 'car_type_p',
  CAR_QUANTITY_AND_CAR_TYPE: 'car_quantity_and_car_type',
  CASH: 'cash',
  CENTER_MARKER_HELPER: 'center_marker_helper',
  CHAIR: 'chair',
  CHAIRS: 'chairs',
  CHANGE_DATA: 'change_data',
  CHANGING_TABLE: 'changing_table',
  CHAT: 'chat',
  CHEST: 'chest',
  CHEST_OF_DRAWERS: 'chest_of_drawers',
  CHOSE: 'chose',
  CHOSEN: 'chosen',
  CITY: 'city',
  CLEAR: 'clear',
  CLIENT: 'client',
  CLIENT_TEL_MAIN: 'client_tel_main',
  CLOCK: 'clock',
  CLOCK_STAND: 'clock_stand',
  CLOSE: 'close',
  CLOSE_DRIVE: 'close_drive',
  COAT_STAND: 'coat_stand',
  COFFEE_TABLE: 'coffee_table',
  COMMENT: 'comment',
  COMMENT2: 'comment2',
  COMMENTS: 'comments',
  COMMENT_DESCRIPTION: 'comment_description',
  COMPANY: 'company',
  COMPANY_NAME: 'company_name',
  COMPUTER_DESK: 'computer_desk',
  CONFIRM_LOCATION: 'confirm_location',
  CONFLICT_WITH_RIDER: 'conflict_with_rider',
  CONTACTS_P: 'contacts_p',
  CONTINUE_WAITING: 'continue_waiting',
  CORPORATE_BANK_CARD: 'corporate_bank_card',
  COST: 'cost',
  COUCH: 'couch',
  COURIER_MISSION: 'courier_mission',
  COURIER_TRANSPORT: 'courier_transport',
  CREATE_ORDER: 'create_order',
  CRIB: 'crib',
  CUPBOARD: 'cupboard',
  CURRENT_LOCATION: 'current_location',
  CUSTOMER: 'customer',
  CUSTOMER_P: 'customer_p',
  CUSTOMER_PRICE: 'customer_price',
  CUSTOM_COMMENT: 'custom_comment',
  CVC_CODE: 'cvc_code',
  CVC_PATTERN_ERROR: 'cvc_pattern_error',
  DATABASE_IS_UNAVAILABLE: 'database_is_unavailable',
  DATE_P: 'date_p',
  DATE_FROM: 'date_from',
  DATE_TILL: 'date_till',
  DECEMBER: 'december',
  DELIVERY: 'delivery',
  DELIVERY_INFO: 'delivery_info',
  DELIVER_PACKAGE: 'deliver_package',
  DESK: 'desk',
  DESTINATION_LOCATION: 'destination_location',
  DESTINATION_POINT: 'destination_point',
  DINNING_ROOM: 'dinning_room',
  DINNING_ROOM_CABINET: 'dinning_room_cabinet',
  DINNER_TABLE: 'dinner_table',
  DINNING_CHAIRS: 'dinning_chairs',
  DINNING_TABLE: 'dinning_table',
  DISCHARGE_P: 'discharge_p',
  DISCHARGE_PLACE_P: 'discharge_place_p',
  DISTANCE: 'distance',
  DIVING_ROOM: 'diving_room',
  DOCUMENTS: 'documents',
  DRESSER: 'dresser',
  DRESSER_WITH_MIRROR: 'dresser_with_mirror',
  DRESSING_TABLE: 'dressing_table',
  DRINKS_CABINET: 'drinks_cabinet',
  DRIVE: 'drive',
  DRIVER: 'driver',
  DRIVE_NUMBER: 'drive_number',
  DRIVING_HEADER: 'driving_header',
  DRIVING_TIME: 'driving_time',
  DROVE_PASSENGER: 'drove_passenger',
  DRYER: 'dryer',
  DUMBBELLS: 'dumbbells',
  EDIT_LANGS_BACK_TO_LIST: 'edit_langs_back_to_list',
  EDIT_LANGS_PAGE_TITLE: 'edit_langs_page_title',
  ELEVATOR: 'elevator',
  ELLIPTICAL_MACHINE: 'elliptical_machine',
  EMAIL_P: 'email_p',
  EMAIL: 'email',
  PASSPORT_PHOTO: 'passport_photo',
  DRIVER_LICENSE_PHOTO: 'driver_license_photo',
  LICENSE_PHOTO: 'license_photo',
  USE_THE: 'use_p',
  EMAIL_ERROR: 'email_error',
  EMAIL_REGISTER_BODY: 'email_register_body',
  EMAIL_REGISTER_SUBJECT: 'email_register_subject',
  EMAIL_REMIND_BODY: 'email_remind_body',
  EMAIL_REMIND_SUBJECT: 'email_remind_subject',
  EMPTY_STAR: 'empty_star',
  ENTER_MOVE: 'enter_move',
  ERROR: 'error',
  ESTIMATE: 'estimate',
  ESTIMATE_TIME: 'estimate_time',
  EXECUTOR_P: 'executor_p',
  EXERCISE_BIKE: 'exercise_bike',
  EXIT: 'exit',
  EXIT_NOT_AVIABLE: 'exit_not_aviable',
  EXIT_USER_CANCELLED: 'exit_user_cancelled',
  EXPECTED_DURATION: 'expected_duration',
  EXPIRES: 'expires',
  EXTRA_LARGE_BOX: 'extra_large_box',
  FEBRUARY: 'february',
  FETCHING: 'fetching',
  FILE_CABINET: 'file_cabinet',
  FILING_CABINET: 'filing_cabinet',
  FINISHED: 'finished',
  FIXED: 'fixed',
  FLIGHT: 'flight',
  FLOOR: 'floor',
  FLOOR_LAMP: 'floor_lamp',
  FLOWERS: 'flowers',
  FOLDING_CHAIRS: 'folding_chairs',
  FREE: 'free',
  FROM: 'from',
  FULL_MATTRESS: 'full_mattress',
  FULL_BED: 'full_bed',
  FULL_BOX_SPRING: 'full_box_spring',
  FULL_STAR: 'full_star',
  FURNITURE_LIST: 'furniture_list',
  GAMING_DESK: 'gaming_desk',
  GARAGE: 'garage',
  GIFT: 'gift',
  GLASS_DISPLAY_CABINET: 'glass_display_cabinet',
  GLASS_TABLE: 'glass_table',
  GRILLS: 'grills',
  GROCERIES: 'groceries',
  HANDY_MOVING: 'handy_moving',
  HIDE_ORDER: 'hide_order',
  HIGH_CHAIR: 'high_chair',
  HOME_P: 'home_p',
  HOUR: 'hour',
  IMESSAGE1: 'iMessage1',
  INTERCITY: 'intercity',
  INTERCITY_P: 'intercity_p',
  INTERNATIONAL_P: 'international_p',
  INTERSTATE: 'interstate',
  IS_WAITING: 'is_waiting',
  JANUARY: 'january',
  JULY: 'july',
  JUNE: 'june',
  KG: 'kg',
  KIDS_BICYCLE: 'kids_bicycle',
  KING_BED: 'king_bed',
  KING_BOX_SPRING: 'king_box_spring',
  KING_MATTRESS: 'king_mattress',
  KITCHEN: 'kitchen',
  KM: 'km',
  LARGE_BOX: 'large_box',
  LARGE_FRIDGE: 'large_fridge',
  LARGE_PACKAGE: 'large_package',
  LAUNDRY_ROOM: 'laundry_room',
  LAWN_MOWER: 'lawn_mower',
  LEFT: 'left',
  LESS_THAN_600: 'less_than_600',
  LESS_TIME_ERROR: 'less_time_error',
  LIGHT: 'light',
  LIGHT_AUTO: 'light_auto',
  LIVING_ROOM: 'living_room',
  LOADING: 'loading',
  LOADING_P: 'loading_p',
  LOADING_PLACE_P: 'loading_place_p',
  LOGIN: 'login',
  LOGIN_FAIL: 'login_fail',
  LOGIN_SUCCESS: 'login_success',
  LOGOUT: 'logout',
  LOGOUT_FAIL: 'logout_fail',
  LOGOUT_SUCCESS: 'logout_success',
  LOVESEAT: 'loveseat',
  LOVE_SEAT: 'love_seat',
  LOW_CABINET: 'low_cabinet',
  L_BOX: 'l_box',
  MAP: 'map',
  MAP_FROM_NOT_SPECIFIED_ERROR: 'map_from_not_specified_error',
  MAP_TO_NOT_SPECIFIED_ERROR: 'map_to_not_specified_error',
  MARCH: 'march',
  MAY: 'may',
  MEDIUM_BOX: 'medium_box',
  MENU: 'menu',
  MESSAGE: 'message',
  MESSAGE_CHAIR: 'message_chair',
  MIDDLE_NAME: 'middle_name',
  MINUTES: 'minutes',
  MIRROR: 'mirror',
  MIRRORED_WARDROBE: 'mirrored_wardrobe',
  MISTAKENLY_ORDERED: 'mistakenly_ordered',
  MORE_THAN: 'more_than',
  MOTORCYCLE: 'motorcycle',
  MOVING_THINGS: 'moving_things',
  WAGON_THINGS: 'wagon_thins',
  NAME: 'name',
  NAME_AND_SURNAME: 'name_and_surname',
  NAME_AND_SURNAME_PLACEHOLDER: 'name_and_surname_placeholder',
  NAME_CITY: 'name_city',
  NIGHT_LAMP: 'night_lamp',
  NIGHT_STAND: 'night_stand',
  NO: 'no',
  NOT_AVIABLE_ORDER: 'not_aviable_order',
  NOT_IMPORTANT: 'not_important',
  NOT_LINKED_CAR: 'not_linked_car',
  NOT_REQUIRED: 'not_required',
  NOT_SPECIFIED: 'not_specified',
  NOVEMBER: 'november',
  NOW: 'now',
  NO_ACTUAL_DRIVE: 'no_actual_drive',
  NO_FINISH_POINT_ERROR: 'no_finish_point_error',
  NO_MATTER: 'no_matter',
  NO_START_POINT_ERROR: 'no_start_point_error',
  NUMBER_FROM: 'number_from',
  NUMBER_OF_STEPS: 'number_of_steps',
  NUMBER_TILL: 'number_till',
  OCTOBER: 'october',
  OFFICE: 'office',
  OFFICE_CHAIR: 'office_chair',
  OFFICE_DESK: 'office_desk',
  OK: 'ok',
  ONLY_ONE_TIME: 'only_one_time',
  ORDER: 'order',
  ORDERS: 'orders',
  ORDERS_HISTORY: 'orders_history',
  ORDER_DETAILS: 'order_details',
  OTHERS: 'others',
  OTTOMAN: 'ottoman',
  OUTDOOR_CHILDREN_FACILITIES: 'outdoor_children_facilities',
  OUTDOOR_DINING_SET: 'outdoor_dining_set',
  OUTDOOR_GRILL: 'outdoor_grill',
  OUTDOOR_KIDS_TOYS: 'outdoor_kids_toys',
  OUTDOOR_LOUNGE_SET: 'outdoor_lounge_set',
  OUTDOOR_UMBRELLA: 'outdoor_umbrella',
  PASSENGER: 'passenger',
  PASSWORD: 'password',
  PASSWORD_RESET_MESSAGE: 'password_reset_message',
  PATIO: 'patio',
  PAYMENT_FOR_ORDER: 'payment_for_order',
  PAYMENT_WAY: 'payment_way',
  PEDESTRIAN: 'pedestrian',
  PHONE: 'phone',
  PHONE_P: 'phone_p',
  PHONE_PATTERN_ERROR: 'phone_pattern_error',
  PHONE_TO_CALL: 'phone_to_call',
  PIANO: 'piano',
  PICKUP_DATE: 'pickup_date',
  PICKUP_TIME: 'pickup_time',
  PICK_UP: 'pick_up',
  PICK_UP_ORDER: 'pick_up_order',
  PICK_UP_PACKAGE: 'pick_up_package',
  PINNED: 'pinned',
  PLASTIC_BOX: 'plastic_box',
  PLASTIC_CONTEINERS_LARGE: 'plastic_conteiners_large',
  PLASTIC_CONTEINERS_MEDIUM: 'plastic_conteiners_medium',
  PLASTIC_CONTEINERS_SMALL: 'plastic_conteiners_small',
  PLUS: 'plus',
  POINT_ROUTE: 'point_route',
  PORCH: 'porch',
  PRICE_P: 'price_p',
  PROFILE: 'profile',
  PROMO_CODE: 'promo_code',
  PROVE: 'prove',
  QUEEN_BED: 'queen_bed',
  QUEEN_BOX_SPRING: 'queen_box_spring',
  QUEEN_MATTRESS: 'queen_mattress',
  QUIT: 'quit',
  RATE: 'rate',
  RATE_DRIVE: 'rate_drive',
  RATING: 'rating',
  RATING_HEADER: 'rating_header',
  RECOMMENDED: 'recommended',
  REFRIGERATOR_P: 'refrigerator_p',
  REGISTER_FAIL: 'register_fail',
  REGISTER_SUCCESS: 'register_success',
  REGISTER_CAR_FAIL: 'register_car_fail',
  REGISTRATION: 'registration',
  REGISTRATION_PLATE: 'registration_plate',
  REMIND_PASSWORD_FAIL: 'remind_password_fail',
  REMIND_PASSWORD_SUCCESS: 'remind_password_success',
  REPEATED: 'repeated',
  REQUESTED: 'requested',
  REQUIRED: 'required',
  REQUIRED_FIELD: 'required_field',
  REQUIRED_FILE: 'required_file',
  REQUIRED_FILE_IMAGE_TYPE: 'required_file_image_type',
  CURRENT_LOCATION_IS_FORBIDDEN: 'current_location_is_forbidden',
  RESPONDING_PERFORMERS: 'responding_performers',
  RESTORE_PASSWORD: 'restore_password',
  RESTORE_PASSWORD_SUCCESS: 'restore_password_success',
  RETURN: 'return',
  ROOM: 'room',
  ROOM_LIST: 'room_list',
  RUG: 'rug',
  SAME_DAY_PICK_UP: 'same_day_pick_up',
  SAME_STATE: 'same_state',
  SAVE: 'save',
  SAVE_P: 'save_p',
  SEARCH: 'search',
  SEATS: 'seats',
  SECONDS: 'seconds',
  SELECT_ONE_OPTION_FROM_THE_LIST: 'select_one_option_from_the_list',
  SELF_EMPLOYED: 'self_employed',
  SUCCESS_PROFILE_UPDATE_MESSAGE: 'success_profile_update_message',
  SUCCESS_REGISTER_MESSAGE: 'success_register_message',
  SEND_PARCEL: 'send_parcel',
  SEPTEMBER: 'september',
  SET_ADJUSTABLE_POINT: 'set_adjustable_point',
  SET_FROM_POINT: 'set_from_point',
  SET_TO_POINT: 'set_to_point',
  SHELF: 'shelf',
  SHIPPING_APPLICATION_P: 'shipping_application_p',
  SIGNIN: 'signin',
  SIGNUP: 'signup',
  SIGN_IN: 'sign_in',
  SIGN_IN_HEADER: 'sign_in_header',
  SMALL_BOX: 'small_box',
  SMALL_FRIDGE: 'small_fridge',
  SOFA: 'sofa',
  SOFA2: 'sofa2',
  SOFA_BED: 'sofa_bed',
  SOFA_SECTIONAL: 'sofa_sectional',
  STARS: 'stars',
  START_POINT: 'start_point',
  TIME_P: 'time_p',
  START_TIME: 'start_time',
  STATE: 'state',
  STEPS: 'steps',
  STORAGE: 'storage',
  STREET_ADDRESS: 'street_address',
  STRENGTH_TRAINING_MACHINE: 'strength_training_machine',
  SURNAME: 'surname',
  S_BOX: 'S_box',
  TABLE: 'table',
  TAKE: 'take',
  TAKEN: 'taken',
  TAKE_ORDER: 'take_order',
  TALL_CHEST: 'tall_chest',
  TALL_LAMP: 'tall_lamp',
  TEXT_ON_THE_TABLE: 'text_on_the_table',
  TIE_CARD_ARTICLE: 'tie_card_article',
  TIE_CARD_DESCRIPTION: 'tie_card_description',
  TIE_CARD_HEADER: 'tie_card_header',
  TIMER: 'timer',
  TIME_FROM: 'time_from',
  TIME_TILL: 'time_till',
  TO: 'to',
  TODAY: 'today',
  TOKEN: 'token',
  TOMORROW: 'tomorrow',
  TOOK_PASSENGER: 'took_passenger',
  TOOL_BOX: 'tool_box',
  TOP_P: 'top_p',
  TOY_STORAGE: 'toy_storage',
  TO_ORDER: 'to_order',
  TRAMPOLINE: 'trampoline',
  TRANSFER_DATE: 'transfer_date',
  TRANSPORTATION_DATE: 'transportation_date',
  TRANSPORT_P: 'transport_p',
  TRASH_CAN: 'trash_can',
  TREADMILL: 'treadmill',
  TRIP: 'trip',
  TRIPS_P: 'trips_p',
  TRUCK: 'truck',
  TV: 'tv',
  TV_OVER_75: 'tv_over_75',
  TV_STAND: 'tv_stand',
  TV_STORAGE_GLASS: 'tv_storage_glass',
  TV_TABLE: 'tv_table',
  TV_UNDER_75: 'tv_under_75',
  TWIN_BED: 'twin_bed',
  TWIN_BOX_SPRING: 'twin_box_spring',
  TWIN_MATTRESS: 'twin_mattress',
  TYPE_RELOCATION: 'type_relocation',
  UMBRELLA: 'umbrella',
  UP_TO_100: 'up_to_100',
  URBAN: 'urban',
  URBAN_P: 'urban_p',
  URGENTLY: 'urgently',
  USER_IS_NOT_PERFORMER_ERROR: 'user_is_not_performer_error',
  U_HASH: 'u_hash',
  UNAUTHORIZED_ACCESS: 'unauthorized_access',
  VERY_EXPENSIVE: 'very_expensive',
  VIEW_P: 'view_p',
  VOTE: 'vote',
  VOTER: 'voter',
  VOTING: 'voting',
  WAGON: 'wagon',
  WAITING: 'waiting',
  WAITING_FOR_LONG: 'waiting_for_long',
  WARDROBE: 'wardrobe',
  WARDROBE_BOX: 'wardrobe_box',
  WASHER: 'washer',
  WAY: 'way',
  WENT: 'went',
  WHAT_PICK_UP: 'what_pick_up',
  WHAT_WE_DELIVERING: 'what_we_delivering',
  WHEN: 'when',
  WHERE_FROM_P: 'where_from_p',
  WHERE_P: 'where_p',
  WORKOUT_BENCH: 'workout_bench',
  WRITE_COMMENT: 'write_comment',
  WRITE_COURIER_MISSION: 'write_courier_mission',
  WRONG_USER_ROLE: 'wrong_user_role',
  XL_BOX: 'xl_box',
  YARD: 'yard',
  YES: 'yes',
  YOUR_OFFER_SENT: 'your_offer_sent',
  YOUR_ORDER_DESCRIPTION: 'your_order_description',
  YOUR_ORDER_HEADER: 'your_order_header',
  YOUR_RATING: 'your_rating',
  YOUR_WORK_TYPE: 'your_work_type',
  ZIP_CODE: 'zip_code',
  AND: 'and_p',
  OR: 'or_p',
  CODE_INFO: 'code_get_information',
  REF_CODE_INFO: 'ref_code_information',
  REF_CODE_NOT_FOUND: 'ref_code_not_found',
  CODE_WRITE: 'code_write_here',
  CODE_ERROR: 'code_error',
  MATCH_LIST_HEADER: 'match_list_header',
  FILTER_TOURNAMENTS_PLACEHOLDER: 'filter_tournaments_placeholder',
  FILTER_TEAM_PLACEHOLDER: 'filter_team_placeholder',
  FILTER_RESET: 'filter_reset',
  TICKETS_IN_STOCK: 'tickets_in_stock',
  TICKETS_SOLD: 'tickets_sold',
  PRICE_FROM: 'price_from',
  FAILED_PAYMENT_LINK_MESSAGE: 'failed_payment_link_message',
} as const