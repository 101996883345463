import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { t } from '../../localization'
import { withLayout } from '../../HOCs/withLayout'
import './styles.scss'

function Content() {
  const location = useLocation()
  const [ content, setContent ] = useState<any>({})
  useEffect(() => {
    const { pathname } = location
    const pageName = pathname.split('/')[1]
    setContent({
      default: t(`page_content_${pageName}`)
    })
  }, [location])

  return (
    <div>
      {!!content.title && <h1 style={{ margin: '0 20px' }}>{content.title}</h1>}
      <div
        dangerouslySetInnerHTML={{
          __html: content.default
        }}
        className='content'
      />
    </div>
  )
}

export default withLayout(Content)