import React, { useState, useMemo, useCallback, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import cn from 'classnames'
import moment from 'moment'
import 'moment/locale/ru'
import 'moment/locale/fr'
import Bell from '../Icon/bell'
import * as API from '../../API'
import { EStatuses } from '../../types/types'
import { CURRENCY } from '../../siteConstants'
import { IRootState } from '../../state'
import { configSelectors } from '../../state/config'
import { userSelectors } from '../../state/user'
import { cartActionCreators } from '../../state/cart'
import { modalsActionCreators } from '../../state/modals'
import Toggle from '../Toggle'
import Stadium from '../Stadium'
import './styles.scss'
import images from '../../constants/images'

function capitalizeFirstLetter(str: string) {
  return str[0].toUpperCase() + str.substr(1)
}

const mapStateToProps = (state: IRootState) => ({
  language: configSelectors.language(state),
  user: userSelectors.user(state),
})

const mapDispatchToProps = {
  bookTicket: cartActionCreators.bookTicket,
  addToNotify: cartActionCreators.addToNotify,
  setLoginModal: modalsActionCreators.setLoginModal,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

function Match({
  id,
  language,
  datetime,
  stadium,
  seats = {},
  team1,
  team2,
  tournament,
  ticketsStatus,
  seatsTogether,
  sectionByBlock,
  stadiumScheme,
  stadiumLoaded,
  user,
  bookTicket,
  addToNotify,
  setLoginModal,
}: any) {
  const [ modalState, setModalState ] = useState({ visible: false, section: null, block: null, seats: [] })
  const ref = useRef(null)
  const [ highlightSection, setHighlightSection ] = useState('')
  const [ successNotify, setSuccessNotify ] = useState('')
  const [ checkedSections, setCheckedSections ] = useState<string[]>([])
  const [ filterTicketsCount, setFilterTicketsCount ] = useState(1)
  const [ filterTogether, setFilterTogether ] = useState(false)
  const langCode = language.iso
  const league = tournament[langCode]
  const team1Name = team1[langCode]
  const team2Name = team2[langCode]
  const stadiumName = stadium ? stadium[langCode] : ''
  const time = moment(datetime).locale(langCode)
  const isTickets = ticketsStatus !== EStatuses.Loading && Object.keys(seats).length > 0
  const isImageScheme = typeof stadiumScheme === 'string'

  const hideModal = () => {
    setModalState({ visible: false, section: null, block: null, seats: [] })
  }

  const handleNotify = useCallback((data) => {
    const { section, ...values } = data
    API.notifyCart(values)
      .then(res => {
        if (res.data.status === 'success') {
          setSuccessNotify(section)
        }
      })
  }, [])

  useEffect(() => {
    if (!successNotify) return
    function onClickOutside(e: any) {
      // @ts-ignore
      if (ref.current && !ref.current.contains(e.target)) {
        setSuccessNotify('')
      }
    }

    document.addEventListener('click', onClickOutside)
    return () => document.removeEventListener('click', onClickOutside)
  }, [ref, successNotify])

  const toggleCheckedSection = useCallback((section) => {
    let checked = [ ...checkedSections ]
    if (checked.includes(section)) {
      checked = checked.filter(item => item !== section)
    } else {
      checked.push(section)
    }
    setCheckedSections(checked)
  }, [checkedSections, setCheckedSections])

  const handleBookTicket = useCallback((block, section) => {
    const stadiumId = stadium?.id || team1?.stadium?.id
    const bookSeats: string[] = []
    const together = (seatsTogether.blocks[block] || [])
      .sort((a: any, b: any) => a.count < b.count ? -1 : 1)
      .filter((item: any) => {
        if (!filterTogether) return true
        return item.count >= filterTicketsCount
      })

    together.forEach((item: any) => {
      const seat = item.start % 1000
      const row = Math.floor(item.start / 1000)
      for (var i = 0; i < item.count; i++) {
        if (bookSeats.length === filterTicketsCount) break
        bookSeats.push(`${block};${row};${seat + i}`)
      }
    })
    
    const bookTickets = bookSeats.reduce((acc, seat) => ({
      ...acc,
      [`${stadiumId};${seat}`]: seats[seat]
    }), {})
    bookTicket({ seats: bookTickets })
    setModalState({
      visible: true,
      section,
      block,
      // @ts-ignore
      seats: bookSeats
    })
    setCheckedSections([])
  }, [
    id,
    seatsTogether,
    filterTogether,
    filterTicketsCount,
    seats,
    bookTicket,
    stadium,
    team1
  ])

  const [ activeSections, inactiveSections, ticketsBySection ] = useMemo(() => {
    if (isImageScheme) {
      const section = 'Stadium'
      const hasSeats = Object.keys(seats).length > 0
      const inactive: any[] = hasSeats ? [] : [section]
      const active: any[] = hasSeats ? [section] : []
      const tickets: Record<string, any> = Object.keys(seats).reduce((acc, seat) => {
        // @ts-ignore
        acc[section] = (acc[section] || []).concat(seats[seat])
        return acc
      }, {})
      return [active, inactive, tickets]
    }

    const sections = Object.keys(stadiumScheme.sections || {}).filter(item => item !== 'unnamed')
    const tickets: Record<string, any> = Object.keys(seats).reduce((acc, seat) => {
      const ticket = seats[seat]
      const section = sectionByBlock[seats[seat].block]
      if (typeof section === 'string') {
        // @ts-ignore
        acc[section] = (acc[section] || []).concat(ticket)
      }
      return acc
    }, {})
    const active = Object.keys(tickets)
    const inactive = sections.filter(name => !active.includes(name))
    return [ active, inactive, tickets ]
  }, [seats, stadiumScheme])

  let activeStadiumSections = activeSections
  if (checkedSections.length > 0) {
    activeStadiumSections = activeStadiumSections.filter(item => checkedSections.includes(item))
  }

  const sectionColors: Record<string, any> = useMemo(() => {
    if (!stadiumScheme || !stadiumScheme.sections) return {}
    const { sections } = stadiumScheme
    return Object.keys(sections).reduce((acc, section) => {
      const { fill = [] } = sections[section] || {}
      // @ts-ignore
      acc[section] = fill
      return acc
    }, {})
  }, [stadiumScheme, isImageScheme])

  return (
    <div className='match'>
      {modalState.visible && <div className='match__modal'>
        <div className='match__modal-overlay' onClick={hideModal} />
        <div className='match__modal-content'>
          <div className='match__modal-header'>
            The ticket is booked.<br />Go to cart to buy it now!
          </div>
          <div className='match__modal-details'>
            <div>
              {league}<br />{stadiumName}<br />
              <b>{team1Name}</b> vs <b>{team2Name}</b>
              <div className='match__modal-date'>
                {time.format('dddd')}<br />
                {time.format('D MMMM')} {time.format('h:mm')}
              </div>
            </div>
            <div>
              {modalState.section}<br />
              {modalState.block}<br />
              <div className='match__modal-ticket-header'>Ticket number</div>
              {modalState.seats.map(seat => (<div key={seat}>{seat}</div>))}
            </div>
          </div>
          <div className='match__modal-footer'>
            <div onClick={hideModal}>
              Stay here
            </div>
            <Link to='/cart'>
              Go to cart
            </Link>
          </div>
        </div>
      </div>}
      <div className='match__header'>
        {league} {stadiumName}
      </div>
      <div className='match__teams'>
        <div className='match__team-wrap'>
          <span className='match__team'>{team1Name}</span>
        </div>
        <span className='match__team-del'>vs</span>
        <div className='match__team-wrap'>
          <span className='match__team'>{team2Name}</span>
        </div>
      </div>
      <div className='match__details'>
        <div>
          <div className='match__date'>
            <div className='match__weekday'>{time.format('dddd')}</div>
            <div className='match__day'>{time.format('D MMMM')} {time.format('h:mm')}</div>
          </div>
        </div>
      </div>
      <div className='match__order'>
        <div className='match__stadium'>
          {stadiumLoaded && !isImageScheme &&
            <Stadium
              onSectionClick={toggleCheckedSection}
              activeSections={activeStadiumSections}
              highlightSection={highlightSection}
              scheme={stadiumScheme}
            />
          }
          {stadiumLoaded && isImageScheme &&
            <img
              src={stadiumScheme}
              style={{ maxWidth: 450 }}
              alt='Stadium scheme'
            />
          }
        </div>
        {ticketsStatus === EStatuses.Loading && 
          <div className='match__tickets'>
            <img src={images.loading} alt='Loading' />
          </div>
        }
        <div className='match__tickets'>
          <div className='match__tickets-header'>
            {isTickets && <span className='match__tickets-step-badge'>step 1</span>}
            {isTickets ? 'Select a section' : 'No tickets available'}
          </div>
          {isTickets &&
            <>
              <div className='match__tickets-list'>
                {activeSections.map((section: string) => {
                  const color = sectionColors[section] || []
                  return (
                    <div
                      className='match__tickets-section'
                      onMouseOver={() => setHighlightSection(section)}
                      onMouseOut={() => setHighlightSection('')}
                      onClick={() => {
                        setHighlightSection('')
                        toggleCheckedSection(section)
                      }}
                      key={section}
                    >
                      <div
                        style={{ backgroundColor: color[highlightSection === section ? 1 : 0] }}
                        className='match__tickets-section-check'
                      >
                        {checkedSections.includes(section) && <img src={images.check} />}
                      </div>
                      <div className='match__tickets-section-name'>
                        {capitalizeFirstLetter(section.split('-').join(' '))}
                      </div>
                    </div>
                  )
                })}
              </div>
              <div className='match__tickets-header'>
                <span className='match__tickets-step-badge'>step 2</span>
                Choose tickets
              </div>
              <div className='match__tickets-filter'>
                <div className='match__tickets-filter-count'>
                  <span
                    className='match__tickets-filter-count-change'
                    onClick={() => filterTicketsCount > 1 && setFilterTicketsCount(filterTicketsCount - 1)}
                  >
                    −
                  </span>
                  <span className='match__tickets-filter-count-value'>
                    {filterTicketsCount} {filterTicketsCount > 1 ? 'tickets' : 'ticket'}
                  </span>
                  <span
                    className='match__tickets-filter-count-change'
                    onClick={() => setFilterTicketsCount(filterTicketsCount + 1)}
                  >
                    +
                  </span>
                </div>

                <div className='match__tickets-filter-together'>
                  <span
                    className='match__tickets-filter-together-label'
                    onClick={() => setFilterTogether(!filterTogether)}
                  >
                    Seated together
                  </span>
                  <Toggle
                    checked={filterTogether}
                    onChange={setFilterTogether}
                  />
                </div>
              </div>
            </>
          }
          <div className='match__tickets-list'>
            {activeSections
              .filter(section => checkedSections.length === 0 || checkedSections.includes(section))
              // @ts-ignore
              .filter(section => (ticketsBySection[section] || []).length >= filterTicketsCount)
              .filter(section => {
                if (!filterTogether) return true
                return seatsTogether.sections[section] >= filterTicketsCount
              })
              .map((section: string) => {
                const color = sectionColors[section] || []
                // @ts-ignore
                const tickets = ticketsBySection[section] || []
                // @ts-ignore
                const ticketsMap = tickets.reduce((acc, ticket) => {
                  if (!acc[ticket.block]) acc[ticket.block] = { count: 1 }
                  else acc[ticket.block].count++
                  acc[ticket.block].row = ticket.row
                  acc[ticket.block].price = ticket.price
                  acc[ticket.block].currency = ticket.currency
                  return acc
                }, {})

                return (
                  <div
                    key={section}
                    className={cn('match__tickets-block', {
                      'match__tickets-block_opened': checkedSections.includes(section)
                    })}
                  >
                    <div className='match__tickets-block-label' style={{ backgroundColor: color[0] }}></div>
                    <div className='match__tickets-block-content'>
                      <div 
                        className='match__tickets-block-header'
                        onClick={() => toggleCheckedSection(section)}
                      >
                        <span>{capitalizeFirstLetter(section.split('-').join(' '))}</span>
                        <span className='match__tickets-block-remain'>
                          <b>{tickets.length}</b> remaining<br />
                          <b>{tickets[0].price} {tickets[0].currency || CURRENCY.SIGN}</b>
                        </span>
                      </div>
                      {Object.keys(ticketsMap)
                        .filter(block => {
                          if (!filterTogether) {
                            return ticketsMap[block].count >= filterTicketsCount
                          }
                          const together = seatsTogether.blocks[block] || []
                          let found = false
                          together.forEach((item: Record<string, any>) => {
                            if (item.count >= filterTicketsCount) found = true
                          })
                          return found
                        })
                        .map(block => {
                          return (
                            <div
                              key={block}
                              className='match__tickets-block-item'
                              onClick={() => user && user.u_id ?
                                handleBookTicket(block, section) :
                                setLoginModal(true)
                              }
                            >
                              <div className='match__tickets-block-info'>
                                {ticketsMap[block].count} tickets<br />
                                Block: {block}<br />
                                Row: {ticketsMap[block].row}
                              </div>
                              <div className='match__tickets-block-price'>
                                {ticketsMap[block].price} {ticketsMap[block].currency || CURRENCY.SIGN}
                              </div>
                              <div className='match__tickets-block-book'>
                                Book now
                              </div>
                            </div>
                          )
                        })}
                    </div>
                  </div>
                )
            })}
            {inactiveSections.map(section => {
              const color = sectionColors[section] || []
              const blocks = isImageScheme ?
                'Stadium' :
                Object.keys(sectionByBlock)
                  .filter(block => sectionByBlock[block] === section)
                  .join(';')
              return (
                <div
                  key={section}
                  className='match__tickets-block'
                >
                  <div className='match__tickets-block-label' style={{ backgroundColor: color[0] }}></div>
                  <div className='match__tickets-block-content'>
                    {successNotify === section &&
                      <div
                        className='match__tickets-success-notify'
                        ref={ref}
                      >
                        The ticket is in the folder «Inform me»<br />
                        <Link to='/profile/tickets/inform'>Open My Profile</Link>
                      </div>
                    }
                    <div 
                      className='match__tickets-block-header'
                      onClick={() => user && user.u_id ?
                        handleNotify({ prod: id, prop: blocks, count: filterTicketsCount, section }) :
                        setLoginModal(true)
                      }
                    >
                      <span>{capitalizeFirstLetter(section.split('-').join(' '))}</span>
                      <span className='match__tickets-block-remain'>
                        <span className='match__tickets-notify'>
                          <Bell /> Notify
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              )}
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default connector(Match)