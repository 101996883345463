import React, { useEffect } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { useLocation } from 'react-router-dom'
import AppRoutes from './Routes'
import Tawk from './components/Tawk'
import CancelOrderModal from './components/modals/CancelModal'
import TimerModal from './components/modals/PickTimeModal'
import CommentsModal from './components/modals/CommentsModal'
import DriverModal from './components/modals/DriverModal'
import RatingModal from './components/modals/RatingModal'
import OnTheWayModal from './components/modals/OnTheWayModal'
import TieCardModal from './components/modals/TieCardModal'
import CardDetailsModal from './components/modals/CardDetailsModal'
import VoteModal from './components/modals/VoteModal'
import LoginModal from './components/modals/login'
import AlarmModal from './components/modals/AlarmModal'
import ProfileModal from './components/modals/ProfileModal'
import CandidatesModal from './components/modals/CandidatesModal'
import MessageModal from './components/modals/MessageModal'
import OrderTicketsModal from './components/modals/OrderTicketsModal'
import MyTicketsModal from './components/modals/MyTicketsModal'
import Config from './config'
import SITE_CONSTANTS from './siteConstants'
import MetaTags from 'react-meta-tags'
import { configActionCreators, configSelectors } from './state/config'
import { userActionCreators, userSelectors } from './state/user'
import { cartActionCreators } from './state/cart'
import { dataActionCreators } from './state/data'
import './App.scss'
import { IRootState } from './state'
import { EStatuses } from './types/types'
import * as API from './API'
import { modalsSelectors, modalsActionCreators } from './state/modals'
import Chat from './components/Chat'
import WACodeModal from './components/modals/login/WACodeModal'
import RefCodeModal from './components/modals/login/RefCodeModal'

const mapStateToProps = (state: IRootState) => ({
  language: configSelectors.language(state),
  configStatus: configSelectors.status(state),
  activeChat: modalsSelectors.activeChat(state),
  user: userSelectors.user(state),
})

const mapDispatchToProps = {
  clearConfig: configActionCreators.clearConfig,
  checkConfig: configActionCreators.checkConfig,
  initUser: userActionCreators.initUser,
  setData: dataActionCreators.setData,
  setDataLoaded: dataActionCreators.setDataLoaded,
  setDataLoading: dataActionCreators.setDataLoading,
  getAllTickets: dataActionCreators.getMatchTicket,
  getCartTickets: cartActionCreators.getTickets,
  setMessageModal: modalsActionCreators.setMessageModal,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

interface IProps extends ConnectedProps<typeof connector> {
}

const App: React.FC<IProps> = ({
  language,
  activeChat,
  user,
  configStatus,
  clearConfig,
  checkConfig,
  initUser,
  setData,
  setDataLoaded,
  setDataLoading,
  getAllTickets,
  getCartTickets,
  setMessageModal,
}) => {
  if ((window as any).ReactNativeWebView) {
    (window as any).ReactNativeWebView.postMessage(
      JSON.stringify({ type: 'SYSTEM', message: 'START' }),
    )
  }

  const location = useLocation()

  useEffect(() => {
    initUser()
    setDataLoading(true)
    API.getData(['stadium', 'schedule', 'team', 'tournament'], { easy: true }).then((res: Record<string, any>) => {
      setData(res)
      setDataLoading(false)
      setDataLoaded(true)
      getAllTickets()
      getCartTickets()
    })
    let _params = new URLSearchParams(window.location.search),
      _config = _params.get('config'),
      _clearConfig = _params.get('clearConfig') !== null

    if (_clearConfig) {
      clearConfig()
    } else {
      if (_config) {
        checkConfig(_config)
      }
    }

    if (!!_config) {
      _params.delete('config')
    }
    if (!!_clearConfig) {
      _params.delete('clearConfig')
    }

    if (_config || _clearConfig) {
      const _path = window.location.origin + window.location.pathname
      let _newUrl = _params.toString() ?
        _path + '?' + _params.toString() :
        _path
      window.history.replaceState({}, document.title, _newUrl)
    } else {
      let _savedConfig = Config.SavedConfig
      if (!!_savedConfig) {
        checkConfig(_savedConfig)
      } else {
        Config.setDefaultName()
      }
    }

    if (location.search && location.search.includes('?status=')) {
      const matchRes = location.search.match(/\?status=(.*?)&/g)
      if (matchRes) {
        const str = matchRes[0]
        const value = str.split('=')[1]
        let status
        let message
        if (value === 'failed&') {
          status = EStatuses.Fail
          message = 'Payment failed, please try again'
        } else if (value === 'succeeded&') {
          status = EStatuses.Success
          message = 'Tickets paid successfully'
        } else if (value === 'processing&') {
          status = EStatuses.Warning
          message = 'Payment is being processed'
        }
        setMessageModal({
          isOpen: true,
          status,
          message,
        })
      }
    }

    // API.activateChatServer()
    // const interval = setInterval(() => API.activateChatServer(), 30000)
    // return () => {
    //   clearInterval(interval)
    // }
  }, [])

  const getMetaTags = () => {
    let _tags = [],
      _domain = `${window.location.protocol}//${window.location.host}/`

    _tags.push(
      SITE_CONSTANTS.OG_IMAGE && (
        <meta property="og:image" content={_domain + SITE_CONSTANTS.OG_IMAGE}/>
      ),
    )
    _tags.push(
      SITE_CONSTANTS.TW_IMAGE && (
        <meta
          property="twitter:image"
          content={_domain + SITE_CONSTANTS.TW_IMAGE}
        />
      ),
    )
    _tags.push(
      <style>{`
    .colored { 
      color: ${SITE_CONSTANTS.PALETTE.primary.dark}
    }

    section details summary {
      color: ${SITE_CONSTANTS.PALETTE.primary.dark};
    }
    section details summary::after {
      border-top: 10px solid ${SITE_CONSTANTS.PALETTE.primary.main};
    }

    .modal .active {
      color: ${SITE_CONSTANTS.PALETTE.primary.dark}
    }
    .modal form fieldset h3, .modal form fieldset h4 {
      color: ${SITE_CONSTANTS.PALETTE.primary.dark}
    }

    .phone-link {
      border-bottom: 1px solid ${SITE_CONSTANTS.PALETTE.primary.light};
    }
    .phone-link:hover {
      border-bottom-color: ${SITE_CONSTANTS.PALETTE.primary.dark};
    }
    `}</style>,
    )

    _tags = _tags.filter((item) => !!item)
    return _tags.length > 0 ? <MetaTags>{_tags}</MetaTags> : null
  }

  return (
    <React.Fragment key={`${language.id}_${configStatus}`}>
      {getMetaTags()}
      <AppRoutes/>
      {/* <PositionTracker/> */}
      <VoteModal/>
      <TimerModal/>
      <CommentsModal/>
      <DriverModal/>
      <OnTheWayModal/>
      <CancelOrderModal/>
      <RatingModal/>
      <TieCardModal/>
      <CardDetailsModal/>
      <WACodeModal />
      <RefCodeModal />
      <AlarmModal/>
      <LoginModal/>
      <CandidatesModal/>
      <OrderTicketsModal />
      <MyTicketsModal />
      {user && <ProfileModal/>}
      {activeChat && <Chat key={activeChat}/>}
      <MessageModal/>
      <Tawk
        lang={language.iso}
      />
    </React.Fragment>
  )
}

export default connector(App)
