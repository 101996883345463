import { TAction } from '../../types/index'
import { EStatuses, TCartTicket } from '../../types/types'
import { ActionTypes } from './constants'

export const setBooking = (payload: any): TAction => ({
  type: ActionTypes.SET_BOOKING,
  payload,
})
export const setPaid = (payload: any): TAction => ({
  type: ActionTypes.SET_PAID,
  payload,
})
export const setInformTickets = (payload: any): TAction => ({
  type: ActionTypes.SET_INFORM_TICKETS,
  payload,
})
export const setBookingCount = (payload: any): TAction => ({
  type: ActionTypes.SET_BOOKING_COUNT,
  payload,
})
export const setPaidCount = (payload: any): TAction => ({
  type: ActionTypes.SET_PAID_COUNT,
  payload,
})
export const setStatus = (payload: EStatuses) => ({
  type: ActionTypes.SET_STATUS,
  payload,
})
export const getTickets = () => ({ type: ActionTypes.GET_TICKETS })
export const getPaidTickets = () => ({ type: ActionTypes.GET_PIAD_TICKETS })
export const getInformTickets = () => ({ type: ActionTypes.GET_INFORM_TICKETS })
export const bookTicket = (payload: any) => ({
  type: ActionTypes.BOOK_TICKET,
  payload,
})
export const addToNotify = (payload: TCartTicket) => ({
  type: ActionTypes.ADD_TO_NOTIFY,
  payload
})
export const sendCart = (payload: any) => ({
  type: ActionTypes.SEND_CART,
  payload,
})
export const clearCart = () => ({ type: ActionTypes.CLEAR_CART })