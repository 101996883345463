import { appName } from '../../config'
import { EStatuses } from '../../types/types'

type TTicket = {
  prod: string,
  prop: string,
  booking_limit?: string,
  sc_id: string
}

export interface ICartState {
  booking: TTicket[],
  paid: TTicket[],
  inform: any[],
  bookingCount: number,
  paidCount: number,
  status: EStatuses
}

export const moduleName = 'cart'

const prefix = `${appName}/${moduleName}`

export const ActionTypes = {
  GET_TICKETS: `${prefix}/GET_TICKETS`,
  BOOK_TICKET: `${prefix}/BOOK_TICKET`,
  GET_PIAD_TICKETS: `${prefix}/GET_PAID_TICKETS`,
  GET_INFORM_TICKETS: `${prefix}/GET_INFORM_TICKETS`,
  SET_INFORM_TICKETS: `${prefix}/SET_INFORM_TICKETS`,
  SET_BOOKING: `${prefix}/SET_BOOKING`,
  SET_PAID: `${prefix}/SET_PAID`,
  SET_BOOKING_COUNT: `${prefix}/SET_BOOKING_COUNT`,
  SET_PAID_COUNT: `${prefix}/SET_PAID_COUNT`,
  SET_STATUS: `${prefix}/SET_STATUS`,
  CLEAR_CART: `${prefix}/CLEAR_CART`,
  SEND_CART: `${prefix}/SEND_CART`,
  ADD_TO_NOTIFY: `${prefix}/ADD_TO_NOTIFY`,
} as const
