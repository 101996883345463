import { all, takeEvery, put } from 'redux-saga/effects'
import { ActionTypes } from './constants'
import { TAction } from '../../types'
import { EStatuses } from '../../types/types'
import { call } from '../../tools/sagaUtils'
import * as API from '../../API'

export const saga = function* () {
  yield all([
    takeEvery(ActionTypes.GET_MATCH_TICKET, getMatchTicketsSaga),
  ])
}

const getMatchTicketsSaga = function* (data: TAction) {
  try {
    yield put({
      type: ActionTypes.SET_MATCH_TICKETS_STATUS,
      payload: {
        match: data.payload,
        status: EStatuses.Loading,
      },
    })
    const result: any = yield* call(API.getMatchTickets, data.payload)
    const tickets = result.data?.trip || {}
    const seats = Object.keys(tickets).reduce((res: any, itemId: any) => {
      const item = tickets[itemId]
      if (!item.t_start_address) {
        return res
      }
      const matchId = item.t_start_address.split('\x00')[1]
      if (!item.t_options) return res
      const { price, seats_sold } = item.t_options
      if (!res[matchId]) res[matchId] = {}
      if (!seats_sold) {
        return res
      }
      Object.keys(seats_sold).forEach(block => {
        Object.keys(seats_sold[block]).forEach(row => {
          Object.keys(seats_sold[block][row]).forEach(seatStr => {
            let seats = seatStr.split(';')
            if (seats.length === 2) {
              const [ start, end ] = seats
              seats = []
              for (let i: number = Number(start); i <= Number(end); i++) {
                seats.push(`${i}`)
              }
            }
            const priceIndex = Array.isArray(seats_sold[block][row][seatStr]) && seats_sold[block][row][seatStr][0]
            const priceValue = price[priceIndex] && parseFloat(price[priceIndex])
            const priceCurrency = (price[priceIndex] || '').split(' ')[1]
            seats.forEach(seat => {
              const key = [block, row, seat].join(';')
              res[matchId][key] = {
                block,
                row,
                seat,
                price: priceValue,
                currency: priceCurrency,
                t_id: itemId
              }
            })
          })
        })
      })
      return res
    }, {})

    const statuses = Object.keys(tickets).reduce((res: any, itemId: any) => ({
      ...res,
      [itemId]: EStatuses.Success,
    }), {})

    yield put({ type: ActionTypes.SET_MATCH_TICKETS_STATUS, payload: statuses })
    yield put({ type: ActionTypes.SET_MATCH_TICKETS, payload: seats })
  } catch (error) {
    console.error(error)
  }
}
