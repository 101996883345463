import React, { useCallback, useMemo } from 'react'
import Countdown from 'react-countdown'
import moment from 'moment'
import cn from 'classnames'
import 'moment/locale/fr'
import 'moment/locale/ru'
import { connect, ConnectedProps } from 'react-redux'
import Button from '../../components/Button'
import Breadcrumbs from '../../components/Breadcrumbs'
import { Ball, Trash } from '../../components/Icon'
import { IRootState } from '../../state'
import { userSelectors } from '../../state/user'
import { CURRENCY } from '../../siteConstants'
import { cartSelectors, cartActionCreators } from '../../state/cart'
import { withLayout } from '../../HOCs/withLayout'
import withAuth from '../../HOCs/withAuth'
import { modalsActionCreators } from '../../state/modals'
import { configSelectors } from '../../state/config'
import images from '../../constants/images'
import history from '../../tools/history'
import './styles.scss'

function parseSeat(place: string) {
  const [ stadiumId, block, row, seat ] = place.split(';')
  return `Block ${block}, row ${row}, seat ${seat}`
}

function filterTickets(tickets: any[], isExpired?: boolean) {
  return tickets
    .map((ticket: any) => {
      const { seats } = ticket
      const newSeats = Object.keys(seats).reduce((acc: Record<string, any>, seat) => {
        if (isExpired) {
          if (new Date(seats[seat].bookingLimit).getTime() <= Date.now()) {
            acc[seat] = seats[seat]
          }
        } else {
          if (new Date(seats[seat].bookingLimit).getTime() > Date.now()) {
            acc[seat] = seats[seat]
          }
        }
        return acc
      }, {})
      return {
        ...ticket,
        seats: newSeats
      }
    })
    .filter((ticket: any) => {
      const { seats } = ticket
      return Object.keys(seats).length > 0
    })
}

function CartContent({
  tickets,
  bookingLimit = 0,
  bookingPrice = 0,
  sendCart,
  bookTicket,
  emptyCart,
  lang,
}: {
  tickets: any[],
  bookingLimit?: number,
  bookingPrice?: number,
  sendCart: Function,
  bookTicket: Function,
  emptyCart: Function,
  lang: string
}) {
  const isExpired = !bookingLimit
  if (!tickets.length) return null
  return (
    <div className="cart">
      <div className="cart__top">
        <div className="cart__header">
          {isExpired ?
            'Your tickets are not valid' : 
            <span>Tickets must be <u>purchased before</u></span>
          }
          <div className={cn("cart__countdown", {
            "cart__countdown_expired": isExpired
          })}>
            <span className="cart__countdown-top"></span>
            {isExpired ?
              '00 : 00' :
              <Countdown
                date={bookingLimit}
                renderer={({ minutes, seconds }) => `${minutes} : ${seconds < 10 ? `0${seconds}` : seconds}`}
              />
            }
          </div>
          <div className="cart__header cart__total">
            <span className="cart__total-left">Total</span>
            <span className="cart__total-del" />
            <span className="cart__total-right">{bookingPrice}{CURRENCY.SIGN}</span>
          </div>
        </div>
        <Button
          text={isExpired ? "Empty the trash" : "Buy now"}
          className={cn("cart__submit", {
            "cart__submit_expired": isExpired
          })}
          onClick={() => isExpired ? emptyCart() : sendCart(tickets)}
          style={{
            flex: '0 1',
            margin: '20px auto 0',
            whiteSpace: 'nowrap'
          }}
        />
      </div>
      <div className="cart__tickets">
        {tickets.map((item: any, i: number) => {
          const { match, seats } = item
          if (!match) return null
          const { datetime, team1, team2, tournament, stadium } = match
          const teamHome = team1[lang]
          const teamAway = team2[lang]
          const league = tournament[lang]
          const stadiumName = stadium ? stadium[lang] : team1.stadium[lang]
          const date = moment(datetime).locale(lang)
          return (
            <div className="cart__ticket" key={i}>
              <div className='cart__header'>
                {league} {stadiumName}
              </div>
              <div className='cart__weekday'>
                {date.format('dddd')}
              </div>
              <div className="cart__match">
                <div className="cart__match-team">
                  <div
                    className="cart__match-team-logo"
                    style={{
                      backgroundImage: `url(${team1.logo})`
                    }}
                  />
                  {teamHome}
                </div>
                <div className="cart__match-data">
                  <Ball className="cart__match-ball" />
                  <div className="cart__date">
                    {date.format('D MMMM')}<br />{date.format('HH:mm')}
                  </div>
                </div>
                <div className="cart__match-team">
                <div
                    className="cart__match-team-logo"
                    style={{
                      backgroundImage: `url(${team2.logo})`
                    }}
                  />
                  {teamAway}
                </div>
              </div>
              <div className='cart__details'>
                <div>
                  <div className='cart__date'>
                    <div className='cart__day'></div>
                  </div>
                </div>
              </div>

              <div className="cart__ticket-header">
                Booking {Object.keys(seats).length > 1 ? 'tickets' : 'ticket'}
              </div>
              <div className="cart__ticket-items-wrapper">
                <ul className={cn("cart__ticket-items", {
                  "cart__ticket-items_expired": isExpired
                })}>
                  {Object.keys(seats).map(seat => (
                    <li key={seat}>
                      <span className="cart__ticket-part">{parseSeat(seat)}</span> —&nbsp;
                      <span className="cart__ticket-part">{seats[seat].price}{CURRENCY.SIGN}</span>
                      <span
                        className="cart__remove"
                        onClick={() => {
                          bookTicket({ seats: { [seat]: { t_id: seats[seat].prod } }, count: 0 })
                        }}
                      >
                        <Trash />
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          )
        })}
      </div>
      {!isExpired && <div className="cart__header cart__total">
        <span className="cart__total-left">Total</span>
        <span className="cart__total-del" />
        <span className="cart__total-right">{bookingPrice}{CURRENCY.SIGN}</span>
      </div>}
      {!isExpired && tickets.length > 0 &&
        <Button
          text="Buy now"
          className="cart__submit"
          onClick={() => sendCart(tickets)}
          style={{
            flex: '0 1',
            margin: '20px auto 0',
            whiteSpace: 'nowrap'
          }}
        />
      }
    </div>
  )
}

const mapStateToProps = (state: IRootState) => ({
  user: userSelectors.user(state),
  tickets: cartSelectors.getBooking(state),
  bookingLimit: cartSelectors.getBookingLimit(state),
  bookingPrice: cartSelectors.getBookingPrice(state),
  status: cartSelectors.getCartStatus(state),
  lang: configSelectors.language(state).iso,
})

const mapDispatchToProps = {
  setLoginModal: modalsActionCreators.setLoginModal,
  bookTicket: cartActionCreators.bookTicket,
  sendCart: cartActionCreators.sendCart,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

interface IProps extends ConnectedProps<typeof connector> {
  match: any
}

const Cart: React.FC<IProps> = ({
  bookTicket,
  sendCart,
  bookingLimit,
  bookingPrice,
  tickets,
  lang,
}) => {
  if (!Array.isArray(tickets)) return null

  const actualTickets = useMemo(() => filterTickets(tickets), [tickets])
  const expiredTickets = useMemo(() => filterTickets(tickets, true), [tickets])
  const emptyCart = useCallback(() => {
    const seats = tickets.reduce((acc: Record<string, any>, item) => {
      // @ts-ignore
      const { seats } = item
      if (!seats) return acc
      Object.keys(seats).forEach(seat => {
        acc[seat] = { t_id: seats[seat].t_id }
      })
      return acc
    }, {})
    bookTicket({ seats, count: 0 })
  }, [tickets, bookTicket])

  return (
    <>
      <Breadcrumbs items={[
        {
          title: 'Home',
          link: '/'
        }, {
          title: 'Cart',
          link: '/cart'
        }
      ]} />
      {!tickets.length && <div className="cart">
        <div className="cart__header">
          Your Cart Is Currently Empty
        </div>
        <div className="cart__text">
          You have no tickets in your shopping cart.
          <div className="cart__text-mobile-newline">Add tickets now!</div>

          <div
            className="cart__empty-men"
            style={{
              backgroundImage: `url(${images.emptyCarMen})`,
            }}
          />
          <Button
            text="Add tickets"
            className="cart__empty-btn"
            onClick={() => history.push('/')}
            style={{
              flex: '0 1',
              margin: '0 auto',
              whiteSpace: 'nowrap'
            }}
          />
        </div>
      </div>}
      {actualTickets.length > 0 && <CartContent
        bookTicket={bookTicket}
        sendCart={sendCart}
        lang={lang}
        tickets={actualTickets}
        bookingLimit={bookingLimit}
        bookingPrice={bookingPrice}
        emptyCart={emptyCart}
      />}
      {expiredTickets.length > 0 && <CartContent
        bookTicket={bookTicket}
        sendCart={sendCart}
        lang={lang}
        tickets={expiredTickets}
        emptyCart={emptyCart}
      />}
    </>
  )
}

export default withLayout(withAuth(connector(Cart)))